import styled from "styled-components";
import { motion } from "framer-motion";

export const OurWorkIntroStyle = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0rem 3rem;
  height: 100vh;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 0rem 1rem;
  }
`;

export const IntroLeftStyle = styled(motion.div)`
  flex: 1 0 50%;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  h2 {
    font-size: 2.8rem;

    @media screen and (max-width: 768px) {
      line-height: 2.5rem;
    }
  }

  span {
    color: #fe4b3d;
  }

  p {
    margin-top: 0.5rem;
    line-height: 1.5rem;
    width: 30rem;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const IntroRightStyle = styled(motion.div)`
  flex: 1 0 50%;
`;

export const RecentWrapperStyle = styled.section`
  padding: 0rem 3rem;

  @media screen and (max-width: 768px) {
    padding: 0rem 1rem;
  }
`;

export const RecentOurWorkStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    color: white;
    font-size: 3.6rem;
    @media screen and (max-width: 768px) {
      font-size: 2rem;
    }
  }

  p {
    color: #a5a5a5;
    font-size: 1rem;
  }

  .recent-btns {
    margin-top: 1rem;

    button {
      border: ${(props) =>
        props.checkCategory === "Web" ? "none" : "1px solid #ffffff"};
      padding: 0.6rem 5rem;
      border-radius: 1.5rem;
      font-family: inherit;
      font-weight: bold;
      font-size: 1.5rem;
      margin: 0rem 1rem 0rem 0rem;
      background: ${(props) =>
        props.checkCategory === "Web" ? "#f44336" : "transparent"};
      color: white;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        padding: 0.6rem 2rem;
      }
    }

    button:nth-of-type(2) {
      background: ${(props) =>
        props.checkCategory === "App" ? "#f44336" : "transparent"};
      border: ${(props) =>
        props.checkCategory === "App" ? "none" : "1px solid #ffffff"};
    }
  }
`;

export const RecentListStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  /* display: flex;
  flex-wrap: wrap; */
  row-gap: 3rem;
  column-gap: 3rem;
  padding: 3rem 0rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 1rem;
    column-gap: 1rem;
  }

  .work-card {
    overflow: hidden;
    background: #2d2d2d;
    border-radius: 2rem;
    /* flex: 1 0 40%; */

    @media screen and (max-width: 768px) {
      flex: 1 0 50%;
    }

    img {
      width: 100%;
      height: 23rem;
      object-fit: cover;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: 15rem;
        object-fit: cover;
      }
    }
  }

  .info {
    padding: 1rem 2rem;

    @media screen and (max-width: 768px) {
      padding: 1rem 1rem;
    }

    h4 {
      color: white;
      font-size: 2rem;
    }

    a {
      color: #2196f3;
      font-weight: bold;
    }
  }
`;
