import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BranchWrapperStyle,
  BranchCardStyle,
  BranchContainerStyle,
  ContactFormWrapperStyle,
  FormContainerStyle,
  SaturnStyle,
  MarsStyle,
} from "./contactus.style";
import { PhoneIcon, EmailIcon, MapImg, SaturnImg, MarsImg } from "../../assets";
import { motion } from "framer-motion";
import { toast } from "react-hot-toast";
import { APIS } from "../../utils/Constants";

const ContactUs = () => {
  const contactObj = {
    first_name: "",
    last_name: "",
    mobile_no: "",
    email: "",
    msg_description: "",
  };

  const [formData, setFormData] = useState(contactObj);
  const [loading, setLoading] = useState(false);

  const { first_name, last_name, mobile_no, email, msg_description } = formData;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const clearFormFields = () => {
    setFormData((prevState) => ({
      ...prevState,
      first_name: "",
      last_name: "",
      mobile_no: "",
      email: "",
      msg_description: "",
    }));
  };

  const validateInputs = () => {
    let validateName = /^[A-Za-z.-]+(\s*[A-Za-z.-]+)*$/;
    let validateEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
    let validateMobNo =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

    if (first_name === "") {
      toast.error("Please enter your First Name");
    } else if (!validateName.test(first_name)) {
      setLoading(false);

      toast.error("Please enter valid name");
    }

    if (last_name === "") {
      toast.error("Please enter your Last Name");
    } else if (!validateName.test(last_name)) {
      setLoading(false);

      toast.error("Please enter valid last name");
    }

    if (email === "") {
      toast.error("Please enter your email");
    } else if (!validateEmail.test(email)) {
      setLoading(false);

      toast.error("Please enter valid email");
    }

    if (mobile_no === "") {
      toast.error("Please enter your mobile no");
    } else if (!validateMobNo.test(mobile_no)) {
      setLoading(false);

      toast.error("Please enter valid mobile no");
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const payLoad = {
      first_name,
      last_name,
      mobile_no,
      email,
      msg_description,
    };

    try {
      validateInputs();

      const response = await axios.post(
        APIS.postURLS.createContactUs + "/create",
        payLoad
      );

      if (response.data.status === "success") {
        setLoading(false);
        toast.success("Thank you for getting in touch!");
        clearFormFields();
      } else if (response.data.status === "failed") {
        setLoading(false);
        toast.error("Unable to save contact");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <main
      style={{
        maxWidth: "1500px",
        paddingRight: "calc(var(--bs-gutter-x) * 0.5)",
        paddingLeft: "calc(var(--bs-gutter-x) * 0.5)",
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      <ContactFormWrapperStyle>
        <div className="contact-header">
          <h2>Contact Us</h2>
        </div>
        <SaturnStyle>
          <motion.img
            whileInView={{ rotate: [0, 360] }}
            transition={{ ease: "linear", duration: 15, repeat: Infinity }}
            src={SaturnImg}
            alt="Saturn"
          />
        </SaturnStyle>
        <FormContainerStyle>
          <form onSubmit={handleOnSubmit}>
            <div className="row">
              <div className="input-control">
                <label htmlFor="firstname">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  value={first_name}
                  onChange={handleOnChange}
                  required
                />
              </div>
              <div className="input-control">
                <label htmlFor="lastname">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  value={last_name}
                  required
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="input-control">
                <label htmlFor="mobno">Mobile Number</label>
                <input
                  type="text"
                  name="mobile_no"
                  required
                  maxLength={10}
                  value={mobile_no}
                  onChange={handleOnChange}
                />
              </div>
              <div className="input-control">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  required
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <div className="message-box">
              <label htmlFor="message">Message</label>
              <textarea
                name="msg_description"
                cols="30"
                rows="10"
                value={msg_description}
                onChange={handleOnChange}
              ></textarea>
            </div>

            <div className="submit-btn">
              <button type="submit">
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </FormContainerStyle>
        <MarsStyle>
          <motion.img
            whileInView={{ rotate: [0, 360] }}
            transition={{ ease: "linear", duration: 15, repeat: Infinity }}
            src={MarsImg}
            alt="Mars"
          />
        </MarsStyle>
      </ContactFormWrapperStyle>
      <BranchWrapperStyle>
        <div className="branch-header">
          <h2>Our Branches</h2>
        </div>
        <BranchContainerStyle>
          <BranchCardStyle
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.75 }}
          >
            <div className="location-info">
              <h3>NAGPUR</h3>
              <p>
                103, Ganraya Heights Bhande Plot Square, near Vishal Mega Mart
                Nagpur, Maharashtra 440024
              </p>
            </div>
            <div className="call-info">
              <div className="call">
                <img src={PhoneIcon} alt="Phone Icon" />
                <span>- 7588255795</span>
              </div>

              <div className="call">
                <img src={EmailIcon} alt="Email Icon" />
                <span>thespaceelement@gmail.com</span>
              </div>
            </div>

            <div className="map-info">
              <img src={MapImg} alt="Map" />
            </div>
          </BranchCardStyle>

          <BranchCardStyle
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.75, delay: 0.2 }}
          >
            <div className="location-info">
              <h3>PUNE</h3>
              <p>
                Senapati Bapat Road Flat No. 9, 1st Building, New Ajay Society,
                beside Domino's Pizza, Pune, Maharashtra 411016
              </p>
            </div>
            <div className="call-info">
              <div className="call">
                <img src={PhoneIcon} alt="Phone Icon" />
                <span>- 7588255795</span>
              </div>

              <div className="call">
                <img src={EmailIcon} alt="Email Icon" />
                <span>thespaceelement@gmail.com</span>
              </div>
            </div>

            <div className="map-info">
              <img src={MapImg} alt="Map" />
            </div>
          </BranchCardStyle>

          <BranchCardStyle
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.75, delay: 0.3 }}
          >
            <div className="location-info">
              <h3>MUMBAI</h3>
              <p>
                Plot No. 43, Shop No. 1, Shree Kunj Apart, 44, Belpada Rd,
                Sector 3, Kharghar, Navi Mumbai, Maharashtra 410210
              </p>
            </div>
            <div className="call-info">
              <div className="call">
                <img src={PhoneIcon} alt="Phone Icon" />
                <span>- 7588255795</span>
              </div>

              <div className="call">
                <img src={EmailIcon} alt="Email Icon" />
                <span className="email">thespaceelement@gmail.com</span>
              </div>
            </div>

            <div className="map-info">
              <img src={MapImg} alt="Map" />
            </div>
          </BranchCardStyle>
        </BranchContainerStyle>
      </BranchWrapperStyle>
    </main>
  );
};

export default ContactUs;
