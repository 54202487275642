import styled from "styled-components";

export const OurGallerySecStyle = styled.section`
  padding: 3rem 5rem;

  .gallery-header {
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: white;
      font-size: 4rem;
    }
  }
`;

export const GalleryContainerStyle = styled.section`
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem 5rem;
  row-gap: 1rem;
  column-gap: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 1rem 1rem;
  }

  .card-img-container {
    flex: 1 0 20%;
    cursor: pointer;
    overflow: hidden;
    border-radius: 1rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      object-fit: cover;
      transform: scale(1);
      transition: all 0.5s ease-in-out;

      &:hover {
        transform: scale(1.2);
      }
    }
  }
`;
