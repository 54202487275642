import styled from "styled-components";
import { AboutUsBG } from "../../assets";
import { motion } from "framer-motion";

export const WhySuccessWrapperStyle = styled.section`
  background: url(${AboutUsBG}) no-repeat center/contain;
  height: 100%;
  color: white;
  padding: 3rem 5rem;
  position: relative;
  z-index: 100;

  @media screen and (max-width: 768px) {
    padding: 3rem 1rem;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  .why-success {
    h1 {
      font-size: 4rem;

      @media screen and (max-width: 768px) {
        font-size: 3rem;
      }

      span {
        display: inline-block;
        color: #ec2024;
      }
    }

    p {
      margin-top: 2rem;
      line-height: 2rem;
    }
  }

  .why-success.story {
    margin-top: 3rem;
  }
`;

export const ManageSecStyle = styled.section`
  padding: 3rem 3rem;

  @media screen and (max-width: 768px) {
    padding: 3rem 1rem;
  }

  h1 {
    text-align: center;
    font-size: 4rem;
    color: white;

    @media screen and (max-width: 768px) {
      font-size: 2.5rem;
    }

    span {
      display: inline-block;
      color: #ec2024;
    }
  }
`;

export const ManageOneContainer = styled.div`
  display: flex;
  /* align-items: center; */
  margin-top: 5rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;

    &:nth-of-type(2) {
      flex-direction: column-reverse;
    }
  }

  .profile {
    flex: 1 0 50%;
    position: relative;
    z-index: 500;

    @media screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 20rem;
      height: auto;
      object-fit: cover;
      border-radius: 1rem;
    }

    &::after {
      content: "";
      position: absolute;
      width: 60%;
      height: 70%;
      top: -15%;
      left: 20%;
      border: 2px solid white;
      border-radius: 1rem;
      background-color: #191919;
      z-index: -12;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  .info {
    flex: 1 0 50%;

    @media screen and (max-width: 768px) {
      text-align: center;
      margin-top: 1rem;
    }

    h2 {
      font-size: 3rem;
      color: white;
      font-weight: lighter;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }
    }

    p {
      color: white;
    }
  }
`;

export const OurVisionStyle = styled(motion.section)`
  padding: 0rem 5rem;

  @media screen and (max-width: 768px) {
    padding: 0rem 1rem;
  }

  h1 {
    text-align: center;
    font-size: 4rem;
    color: white;

    @media screen and (max-width: 768px) {
      font-size: 2.5rem;
    }

    span {
      display: inline-block;
      color: #ec2024;
    }
  }

  p {
    margin-top: 2rem;
    color: white;
  }
`;

export const TestimonialsSecStyle = styled.section`
  margin-top: 4rem;

  .testimonials-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 0.4rem solid #f44336;
    background-color: #2d2d2d;
    padding: 2rem 3rem;

    h1 {
      color: white;
      font-size: 3rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }
    }
  }
`;

export const ReviewsContainerStyle = styled.div`
  padding: 3rem;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

  .review {
    padding: 2rem 2rem;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.39) 0%,
      rgba(255, 255, 255, 0.09) 0.01%,
      rgba(255, 255, 255, 0.12) 100%
    );
    border: 2px solid rgba(255, 255, 255, 0.39);
    border-radius: 1rem;

    .stars {
      span {
        display: inline-block;
        font-size: 1.5rem;
        color: white;
      }
    }

    p {
      font-size: 1rem;
      line-height: 1.8rem;
      color: white;
      margin: 2rem 0rem;
    }

    .divider {
      width: 100%;
      height: 0.1rem;
      background: white;
      margin-bottom: 1rem;
    }

    .user {
      display: flex;
      align-items: center;
      gap: 1rem;

      .user-info {
        h4 {
          color: white;
        }
        small {
          display: inline-block;
          color: white;
        }
      }
    }
  }
`;
