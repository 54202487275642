import styled from "styled-components";
import { motion } from "framer-motion";

export const SidebarStyle = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 10vh;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
`;

export const SidebarContainerStyle = styled(motion.div)`
  width: 80%;
  height: 100vh;
`;

export const NavLinkMobileStyle = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  background-color: black;
  width: 100%;
  height: 100%;

  li {
    margin: 2rem 0rem 0rem 1rem;
    overflow: hidden;
  }

  a {
    color: white;
    font-weight: bold;
  }
`;
