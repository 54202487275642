import styled from "styled-components";
import { BGDetail } from "../../assets";
import { motion } from "framer-motion";

export const AppHeaderWrapperStyle = styled(motion.section)`
  height: 100vh;
  background: url(${BGDetail}) no-repeat center/cover;
  padding: 3rem 3rem;

  @media screen and (max-width: 768px) {
    padding: 3rem 1rem;
  }

  .project-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h2 {
      font-size: 3rem;
      color: white;
    }

    p {
      text-align: center;
      color: white;
      width: 50rem;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .group-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 3rem; */

    img {
      width: 50%;
      height: 50%;
      object-fit: contain;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const MobileWrapperStyle = styled.section`
  width: 60rem;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0rem 2rem;
    margin-top: 2rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .mobile-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    h2 {
      color: white;
      font-size: 3rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }
    }
  }
`;

export const MobileContainerStyle = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .mob-row-one {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 2rem;
    }
  }

  .mob-row-two {
    display: flex;
    align-items: center;
    margin-top: 2rem;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 2rem;
    }

    .mobile-img {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1 1 60%;

      img {
        width: 50%;
        height: auto;
        object-fit: cover;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }

  .mobile-img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: 70%;
      height: auto;
      object-fit: cover;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;
