const BASE_URL = "https://core.thespaceelement.com/";
export const IMG_URL = "https://core.thespaceelement.com";

// const BASE_URL = "http://192.168.1.3:6300/";
// export const IMG_URL = "http://192.168.1.3:6300";

export const APIS = {
  getURLS: {
    getAllHirings: BASE_URL + "hiring",
    getAllTestimonials: BASE_URL + "testimonial",
    getAllWebApps: BASE_URL + "workweb",
    getAllMobApps: BASE_URL + "workapp",
    getSingleWeb: BASE_URL + "workweb",
    getSingleApp: BASE_URL + "workapp",
    getAllGallery: BASE_URL + "gallery",
    getAllTraining: BASE_URL + "trainings",
  },
  postURLS: {
    createContactUs: BASE_URL + "contacts",
    createCareer: BASE_URL + "carrier",
    createTrainings: BASE_URL + "trainings/contact",
  },
};
