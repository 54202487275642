import styled, { keyframes } from "styled-components";
import footerBg from "../../assets/footer-bg.png";

export const FooterWrapperStyle = styled.footer`
  color: white;
  background: url(${footerBg}) center/cover no-repeat;
  height: 70vh;
  display: flex;
  justify-content: space-between;
  padding: 0rem 3rem;
  position: relative;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0rem 1rem;
  }
`;

const gradientAnimation = keyframes`
  0%{
    background-position-x: 0;
  }
  100%{
    background-position-x: 133vw;
  }
`;

export const FooterLeftStyle = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .sitemap-two {
    align-self: center;
    position: absolute;
    top: 46%;
    left: 270%;
    width: 100%;

    @media screen and (max-width: 768px) {
      position: unset;
      width: fit-content;
      text-align: center;
      margin-top: 0.5rem;
    }

    ul > li {
      margin: 2rem 0rem 0rem 0rem;

      @media screen and (max-width: 768px) {
        margin: 1rem 0rem 0rem 0rem;
      }
    }

    a {
      color: white;
      font-weight: bold;
    }
  }
`;

export const FooterRightStyle = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FooterLogoStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -2rem;

  @media screen and (max-width: 768px) {
    top: -3rem;
  }

  h3 {
    margin-top: 0.5rem;

    span {
      display: inline-block;
      color: #ec2024;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 5rem;
  }

  img {
    width: 8rem;
    height: 8rem;
    object-fit: cover;

    @media screen and (max-width: 768px) {
      width: 5rem;
      height: 5rem;
    }
  }
`;

export const SitemapLinkStyle = styled.div`
  align-self: center;
  position: absolute;
  top: 46%;
  left: 160%;
  width: 100%;

  @media screen and (max-width: 768px) {
    position: unset;
    width: fit-content;
    text-align: center;
  }

  ul > li {
    margin: 2rem 0rem 0rem 0rem;

    @media screen and (max-width: 768px) {
      margin: 1rem 0rem 0rem 0rem;
    }
  }

  a {
    color: white;
    font-weight: bold;
  }
`;

export const SocialLinksStyle = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  position: relative;
  top: 87%;
  left: 0%;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    position: unset;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-self: unset;
  }

  h5 {
    font-size: 1.5rem;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }

  a {
    margin: 0rem 1rem 0rem 0rem;
  }
`;

export const BorderStyle = styled.div`
  background: linear-gradient(107.63deg, #383838 1.26%, #232323 61.61%), #111111;
  .line {
    width: 100%;
    height: 0.5rem;
    /* background: linear-gradient(90deg, #efb1ff 42.64%, #91ceff 54.81%); */
    /* background-size: 800% 800%; */
    background-image: linear-gradient(
      90deg,
      #29bfff,
      #a231ff,
      #0deccd,
      #29bfff
    );
    background-size: 133vw 4px;
    animation: ${gradientAnimation} 5s linear infinite;
    -webkit-animation: ${gradientAnimation} 5s linear infinite;
    -moz-animation: ${gradientAnimation} 5s linear infinite;
  }

  h3 {
    padding: 1rem 0rem;
    color: white;
    text-align: center;
  }
`;

export const AstronautStyle = styled.div`
  position: absolute;
  top: 10%;
  right: 0%;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const SpaceshipStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
