import styled from "styled-components";
import { motion } from "framer-motion";

import whatwedobg from "../../assets/whatwedobg.png";

// Intro Section styles
export const IntroSectionStyle = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #383838; */
  /* background: linear-gradient(to right, #000000 0%, #383838 50%, #000000 100%); */
  background-color: #000000;
  height: 100vh;
  padding: 0rem 3rem;
  position: relative;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 0rem 1rem;
  }
`;

export const IntroLeftStyle = styled(motion.div)`
  flex: 1 0 50%;
  position: relative;
  z-index: 100;

  .tech-circle {
    position: absolute;
    top: -38%;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: -99;
    opacity: 0.1;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: 5rem;
  }

  h1 {
    color: #ffffff;
    font-size: 4rem;

    @media screen and (max-width: 768px) {
      font-size: 3rem;
    }
  }

  p {
    color: #ffffff;
    font-weight: 500;
    margin-top: 1rem;

    @media screen and (max-width: 768px) {
      margin-top: 0.5rem;
      font-size: 0.8rem;
    }
  }

  .intro-btns {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;

    @media screen and (max-width: 768px) {
      justify-content: center;
      margin-top: 1rem;
    }

    button {
      border: none;
      padding: 1.5rem 2rem;
      font-family: inherit;
      font-size: 1.1rem;
      font-weight: bold;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        padding: 1rem 1rem;
      }
    }

    button:nth-of-type(1) {
      border: 2px solid #f44336;
      background: transparent;
      color: #ffffff;
      border-radius: 0.4rem;
      transition: 0.5s ease;

      &:hover {
        background: #f44336;
      }
    }

    button:nth-of-type(2) {
      background: #ffffff;
      color: #000000;
      border-radius: 0.4rem;
      transition: 0.5s ease;

      &:hover {
        color: #f44336;
      }
    }
  }
`;

export const IntroRightStyle = styled(motion.div)`
  flex: 1 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  position: relative;

  @media screen and (max-width: 768px) {
    display: none;
  }

  .tse-logo {
    position: absolute;
    top: 27%;
  }

  .circle-one {
    width: 10rem;
    height: 10rem;
    border: 1px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 700;

    .photoshop {
      position: absolute;
      top: 8%;
      left: 0;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      z-index: 5;

      .photoshop-title {
        position: absolute;
        top: -70%;
        color: white;
        height: 70px;
        width: 100px;
        clip-path: polygon(
          100% 0,
          100% 38%,
          66% 39%,
          50% 48%,
          34% 39%,
          0 38%,
          0 0
        );
        background-color: #00000093;
        text-align: center;
        font-family: inherit;
        display: none;
      }

      &:hover .photoshop-title {
        display: inline-block;
      }
    }

    .xd {
      position: absolute;
      top: 8%;
      left: 80%;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      z-index: 5;

      .xd-title {
        position: absolute;
        top: -70%;
        color: white;
        height: 70px;
        width: 100px;
        clip-path: polygon(
          100% 0,
          100% 38%,
          66% 39%,
          50% 48%,
          34% 39%,
          0 38%,
          0 0
        );
        background-color: #00000093;
        text-align: center;
        font-family: inherit;
        display: none;
      }

      &:hover .xd-title {
        display: inline-block;
      }
    }

    .figma {
      position: absolute;
      top: 90%;
      left: 40%;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      z-index: 5;

      .figma-title {
        position: absolute;
        top: -70%;
        color: white;
        height: 70px;
        width: 100px;
        clip-path: polygon(
          100% 0,
          100% 38%,
          66% 39%,
          50% 48%,
          34% 39%,
          0 38%,
          0 0
        );
        background-color: #00000093;
        text-align: center;
        font-family: inherit;
        display: none;
      }

      &:hover .figma-title {
        display: inline-block;
      }
    }
  }

  .circle-two {
    width: 20rem;
    height: 20rem;
    border: 1px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 600;

    .html {
      position: absolute;
      top: -5%;
      left: 45%;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      z-index: 5;

      .html-title {
        position: absolute;
        top: -70%;
        color: white;
        height: 70px;
        width: 100px;
        clip-path: polygon(
          100% 0,
          100% 38%,
          66% 39%,
          50% 48%,
          34% 39%,
          0 38%,
          0 0
        );
        background-color: #00000093;
        text-align: center;
        font-family: inherit;
        display: none;
      }

      &:hover .html-title {
        display: inline-block;
      }
    }

    .css {
      position: absolute;
      top: 20%;
      left: 90%;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      z-index: 5;

      .css-title {
        position: absolute;
        top: -70%;
        color: white;
        height: 70px;
        width: 100px;
        clip-path: polygon(
          100% 0,
          100% 38%,
          66% 39%,
          50% 48%,
          34% 39%,
          0 38%,
          0 0
        );
        background-color: #00000093;
        text-align: center;
        font-family: inherit;
        display: none;
      }

      &:hover .css-title {
        display: inline-block;
      }
    }

    .js {
      position: absolute;
      top: 78%;
      left: 80%;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      z-index: 5;

      .js-title {
        position: absolute;
        top: -70%;
        color: white;
        height: 70px;
        width: 100px;
        clip-path: polygon(
          100% 0,
          100% 38%,
          66% 39%,
          50% 48%,
          34% 39%,
          0 38%,
          0 0
        );
        background-color: #00000093;
        text-align: center;
        font-family: inherit;
        display: none;
      }

      &:hover .js-title {
        display: inline-block;
      }
    }

    .bootstrap {
      position: absolute;
      top: 95%;
      left: 45%;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      z-index: 5;

      .bootstrap-title {
        position: absolute;
        top: -70%;
        color: white;
        height: 70px;
        width: 100px;
        clip-path: polygon(
          100% 0,
          100% 38%,
          66% 39%,
          50% 48%,
          34% 39%,
          0 38%,
          0 0
        );
        background-color: #00000093;
        text-align: center;
        font-family: inherit;
        display: none;
      }

      &:hover .bootstrap-title {
        display: inline-block;
      }
    }

    .sass {
      position: absolute;
      top: 75%;
      left: 5%;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      z-index: 5;

      .sass-title {
        position: absolute;
        top: -70%;
        color: white;
        height: 70px;
        width: 100px;
        clip-path: polygon(
          100% 0,
          100% 38%,
          66% 39%,
          50% 48%,
          34% 39%,
          0 38%,
          0 0
        );
        background-color: #00000093;
        text-align: center;
        font-family: inherit;
        display: none;
      }

      &:hover .sass-title {
        display: inline-block;
      }
    }

    .react {
      position: absolute;
      top: 20%;
      left: 0%;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      z-index: 5;

      .react-title {
        position: absolute;
        top: -70%;
        color: white;
        height: 70px;
        width: 100px;
        clip-path: polygon(
          100% 0,
          100% 38%,
          66% 39%,
          50% 48%,
          34% 39%,
          0 38%,
          0 0
        );
        background-color: #00000093;
        text-align: center;
        font-family: inherit;
        display: none;
      }

      &:hover .react-title {
        display: inline-block;
      }
    }
  }

  .circle-three {
    width: 30rem;
    height: 30rem;
    border: 1px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 500;

    .mongo {
      position: absolute;
      top: -3%;
      left: 45%;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      z-index: 5;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .mongo-title {
        position: absolute;
        top: -70%;
        color: white;
        height: 70px;
        width: 100px;
        clip-path: polygon(
          100% 0,
          100% 38%,
          66% 39%,
          50% 48%,
          34% 39%,
          0 38%,
          0 0
        );
        background-color: #00000093;
        text-align: center;
        font-family: inherit;
        display: none;
      }

      &:hover .mongo-title {
        display: inline-block;
      }
    }

    .socket {
      position: absolute;
      top: 20%;
      left: 90%;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      z-index: 5;

      .socket-title {
        position: absolute;
        top: -70%;
        color: white;
        height: 70px;
        width: 100px;
        clip-path: polygon(
          100% 0,
          100% 38%,
          66% 39%,
          50% 48%,
          34% 39%,
          0 38%,
          0 0
        );
        background-color: #00000093;
        text-align: center;
        font-family: inherit;
        display: none;
      }

      &:hover .socket-title {
        display: inline-block;
      }
    }

    .aws {
      position: absolute;
      top: 78%;
      left: 85%;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      z-index: 5;

      .aws-title {
        position: absolute;
        top: -70%;
        color: white;
        height: 70px;
        width: 100px;
        clip-path: polygon(
          100% 0,
          100% 38%,
          66% 39%,
          50% 48%,
          34% 39%,
          0 38%,
          0 0
        );
        background-color: #00000093;
        text-align: center;
        font-family: inherit;
        display: none;
      }

      &:hover .aws-title {
        display: inline-block;
      }
    }

    .mysql {
      position: absolute;
      top: 97%;
      left: 45%;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      z-index: 5;

      .mysql-title {
        position: absolute;
        top: -70%;
        color: white;
        height: 70px;
        width: 100px;
        clip-path: polygon(
          100% 0,
          100% 38%,
          66% 39%,
          50% 48%,
          34% 39%,
          0 38%,
          0 0
        );
        background-color: #00000093;
        text-align: center;
        font-family: inherit;
        display: none;
      }

      &:hover .mysql-title {
        display: inline-block;
      }
    }

    .laravel {
      position: absolute;
      top: 75%;
      left: 5%;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      z-index: 5;

      .laravel-title {
        position: absolute;
        top: -70%;
        color: white;
        height: 70px;
        width: 100px;
        clip-path: polygon(
          100% 0,
          100% 38%,
          66% 39%,
          50% 48%,
          34% 39%,
          0 38%,
          0 0
        );
        background-color: #00000093;
        text-align: center;
        font-family: inherit;
        display: none;
      }

      &:hover .laravel-title {
        display: inline-block;
      }
    }

    .node {
      position: absolute;
      top: 20%;
      left: 3%;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
      z-index: 5;

      .node-title {
        position: absolute;
        top: -70%;
        color: white;
        height: 70px;
        width: 100px;
        clip-path: polygon(
          100% 0,
          100% 38%,
          66% 39%,
          50% 48%,
          34% 39%,
          0 38%,
          0 0
        );
        background-color: #00000093;
        text-align: center;
        font-family: inherit;
        display: none;
      }

      &:hover .node-title {
        display: inline-block;
      }
    }
  }
`;

export const IntroAstronautStyle = styled(motion.div)`
  position: absolute;
  top: 7%;
  left: 40%;
  transform: translate(-50%, -15%);

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-40%, 70%);
    z-index: 500;
  }
`;

export const IntroSpaceshipStyle = styled(motion.div)`
  position: absolute;
  top: 10%;
  left: 40%;
  transform: translate(-125%, -50%);

  @media screen and (max-width: 768px) {
    /* display: flex;
    justify-content: center;
    align-items: center;
    transform: none;
    top: 50%;
    left: 20%;
    z-index: 400; */
    display: none;
  }
`;

/* ---------------What we do styles------------------ */
export const WhatWeDoSecStyle = styled.section`
  width: 100%;
  /* height: 100vh; */
  background: url(${whatwedobg}) center/cover no-repeat;
  color: white;
`;

export const WhatWeDoContainerStyle = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0rem 3rem;
  height: 100vh;
  position: relative;
  z-index: 100;

  .overlay-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0rem 1rem;
  }
`;

export const WhatWeDoLeftStyle = styled(motion.div)`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.17) 0%,
    rgba(255, 255, 255, 0.16) 100%
  );
  filter: blur(0.5px);
  backdrop-filter: blur(13.5px);
  padding: 2rem 5rem;
  border-radius: 0.5rem;
  border: 2px solid #ffffff;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 1rem 1rem;
  }

  img {
    width: 60%;
    height: 60%;
    object-fit: contain;
  }

  .row-one {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    .design,
    .develop {
      display: flex;
      align-items: center;
      flex-direction: column;

      h3 {
        font-size: 2rem;

        @media screen and (max-width: 768px) {
          font-size: 1.5rem;
        }
      }
    }
  }

  .row-two {
    display: flex;
    justify-content: space-between;

    .apps,
    .lab {
      display: flex;
      align-items: center;
      flex-direction: column;

      h3 {
        font-size: 2rem;

        @media screen and (max-width: 768px) {
          font-size: 1.5rem;
        }
      }
    }
  }
`;

export const WhatWeDoRightStyle = styled(motion.div)`
  width: 30rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
    /* position: relative;
    top: -12%; */
  }

  h2 {
    font-size: 4rem;

    @media screen and (max-width: 768px) {
      font-size: 2rem;
    }
  }

  p {
    line-height: 1.5rem;
  }

  span {
    color: #f44336;
  }
`;

/* ---------------Trusted Brands Styles------------------ */
export const TrustedSectiomnStyle = styled.div`
  background-color: #181818;
  /* height: 60vh; */
  position: relative;
  padding-bottom: 3rem;

  h2 {
    text-align: center;
    padding: 2rem;
    color: white;
    font-size: 2rem;
  }
  img {
    width: 100%;
    height: 5rem;
    object-fit: contain;
  }

  .trust-card {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.18) 100%
    );
    border: 1px solid #ffffff;
    filter: blur(0.5px);
    backdrop-filter: blur(12px);
    border-radius: 0.5rem;
    padding: 2em 5rem;
  }
`;

export const TrustedAstroBgStyle = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(0);

  @media screen and (max-width: 768px) {
    display: none;
  }

  img {
    width: 15rem;
    height: 15rem;
    object-fit: cover;
  }
`;

export const TrustedMeteorBgStyle = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;

  @media screen and (max-width: 768px) {
    display: none;
  }

  img {
    width: 15rem;
    height: 15rem;
    object-fit: cover;
  }
`;

/* ---------------Website Design Styles------------------ */
export const WebDesignSecStyle = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 3rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 0rem 1rem;
  }

  .web-design-lottie {
    flex: 1 0 50%;
  }

  .website-design-info {
    flex: 1 10 50%;

    h2 {
      color: #ffa7a7;
      font-size: 4rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }
    }
    p {
      /* width: 25rem; */
      color: white;
      line-height: 1.5rem;
      margin-top: 1rem;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;

/* ---------------Custom Development Styles------------------ */
export const CustomDevSecStyle = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 3rem;
  margin-top: 3rem;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    text-align: center;
    padding: 0rem 1rem;
  }

  .custom-dev-lottie {
    flex: 1 0 50%;
  }

  .custom-dev-info {
    flex: 1 0 50%;
    @media screen and (max-width: 768px) {
      margin-top: 2rem;
    }
    h2 {
      color: #ffa7a7;
      font-size: 4rem;
      line-height: 4rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
        line-height: 1.8rem;
      }
    }
    p {
      /* width: 25rem; */
      color: white;
      line-height: 1.5rem;
      margin-top: 1rem;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }
`;

/* ---------------App Development Styles------------------ */
export const AppDevSecStyle = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 3rem;
  margin-top: 3rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 0rem 1rem;
  }

  .app-dev-lottie {
    flex: 1 0 50%;
  }

  .app-dev-info {
    flex: 1 0 50%;
    @media screen and (max-width: 768px) {
      margin-top: 2rem;
    }
    h2 {
      color: #ffa7a7;
      font-size: 4rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
        line-height: 1.8rem;
      }
    }
    p {
      /* width: 35rem; */
      color: white;
      line-height: 1.5rem;
      margin-top: 1rem;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }
`;
/* ---------------SpreadSheet Styles------------------ */
export const SpreadSheetSecStyle = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 3rem;
  margin-top: 3rem;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    text-align: center;
    padding: 0rem 1rem;
  }

  .spreadsheet-lottie {
    flex: 1 0 50%;
    width: 100%;
    height: 100%;
  }

  .spreadsheet-info {
    flex: 1 0 50%;
    @media screen and (max-width: 768px) {
      margin-top: 2rem;
    }

    h2 {
      color: #ffa7a7;
      font-size: 4rem;
      line-height: 4rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
        line-height: 1.8rem;
      }
    }

    p {
      /* width: 25rem; */
      color: white;
      line-height: 1.5rem;
      margin-top: 1rem;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }
`;
/* ---------------Marketing Styles------------------ */
export const MarketingSecStyle = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 3rem;
  margin-top: 3rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 0rem 1rem;
  }

  .marketing-lottie {
    flex: 1 0 50%;
  }

  .marketing-info {
    flex: 1 0 50%;
    @media screen and (max-width: 768px) {
      margin-top: 2rem;
    }

    h2 {
      color: #ffa7a7;
      font-size: 4rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
        line-height: 1.8rem;
      }
    }
    p {
      /* width: 32rem; */
      color: white;
      line-height: 1.5rem;
      margin-top: 1rem;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }
`;

/* ---------------IOT Styles------------------ */
export const IoTSecStyle = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 3rem;
  margin-top: 3rem;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    text-align: center;
    padding: 0rem 1rem;
  }

  .iot-lottie {
    flex: 1 0 50%;
  }

  .iot-info {
    flex: 1 0 50%;
    @media screen and (max-width: 768px) {
      margin-top: 2rem;
    }

    h2 {
      color: #ffa7a7;
      font-size: 4rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
        line-height: 1.8rem;
      }
    }
    p {
      /* width: 25rem; */
      color: white;
      line-height: 1.5rem;
      margin-top: 1rem;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }
`;
