import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { APIS } from "../../utils/Constants";

import {
  CareerSecStyle,
  CareerFormSecStyle,
  CareerFormContainerStyle,
} from "./career.style";
import { VacancyCard } from "../../components";
import { toast } from "react-hot-toast";

const Career = () => {
  const careerObj = {
    position: "fullstack",
    experiance: "",
    first_name: "",
    last_name: "",
    mobile_no: "",
    email: "",
    gender: "Male",
    dob: "",
    current_company: "",
    current_ctc: "",
    notice_period: "",
    expected_ctc: "",
    city: "",
    address: "",
    qualification: "undergraduate",
    cv_upload: null,
  };

  const [hirings, setHirings] = useState([]);
  const [careerFormData, setCareerFormData] = useState(careerObj);
  const [cvUpload, setCVUplaod] = useState();
  const [loading, setLoading] = useState(false);

  const formRef = useRef(null);

  const {
    position,
    experiance,
    first_name,
    last_name,
    mobile_no,
    email,
    gender,
    dob,
    current_company,
    current_ctc,
    notice_period,
    expected_ctc,
    city,
    address,
    qualification,
  } = careerFormData;

  const fetchHirings = async () => {
    const response = await axios.get(APIS.getURLS.getAllHirings);
    console.log(response);
    try {
      if (response.data.status === "success") {
        setHirings(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchHirings();
  }, []);

  const validateFields = () => {
    let validateText = /^[A-Za-z]*$/;
    let validateNums = /^[1-9]+[0-9]*$/;
    let validateMobNo =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    let validateEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;

    if (!validateText.test(first_name)) {
      setLoading(false);
      toast.error("Please enter valid firstname");
    }

    if (!validateText.test(last_name)) {
      setLoading(false);
      toast.error("Please enter valid lastname");
    }

    if (!validateEmail.test(email)) {
      setLoading(false);
      toast.error("Please enter valid email");
    }

    if (!validateNums.test(experiance)) {
      setLoading(false);
      toast.error("Please enter valid experience");
    }

    if (!validateMobNo.test(mobile_no)) {
      setLoading(false);
      toast.error("Please enter valid mobile number");
    }

    if (!validateNums.test(expected_ctc)) {
      setLoading(false);
      toast.error("Please enter valid expected ctc");
    }

    if (!validateText.test(city)) {
      setLoading(false);
      toast.error("Please enter valid city");
    }

    if (!validateNums.test(current_ctc)) {
      setLoading(false);
      toast.error("Please enter valid ctc");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    validateFields();

    setLoading(true);

    const payload = new FormData();

    payload.append("position", position);
    payload.append("experiance", experiance);
    payload.append("first_name", first_name);
    payload.append("last_name", last_name);
    payload.append("mobile_no", mobile_no);
    payload.append("email", email);
    payload.append("gender", gender);
    payload.append("dob", dob);
    payload.append("current_company", current_company);
    payload.append("current_ctc", current_ctc);
    payload.append("notice_period", notice_period);
    payload.append("expected_ctc", expected_ctc);
    payload.append("city", city);
    payload.append("address", address);
    payload.append("qualification", qualification);
    payload.append("cv_upload", cvUpload);

    try {
      const response = await axios.post(
        APIS.postURLS.createCareer + "/create",
        payload
      );
      if (response.data.status === "success") {
        setLoading(false);
        toast.success("Thank You for Applying");
        clearFormFields();
        formRef.current.reset();
      } else if (response.data.status === "failed") {
        setLoading(false);
        toast.error("Problem while saving form");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (e) => {
    setCareerFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFileChange = (e) => {
    if (e.target.name === "cv_upload") {
      setCVUplaod(e.target.files[0]);
    }
  };

  const clearFormFields = () => {
    setCareerFormData((prevState) => ({
      ...prevState,
      position: "",
      experiance: "",
      first_name: "",
      last_name: "",
      mobile_no: "",
      email: "",
      gender: "",
      dob: "",
      current_company: "",
      current_ctc: "",
      notice_period: "",
      expected_ctc: "",
      city: "",
      address: "",
      qualification: "",
    }));

    setCVUplaod(null);
  };

  return (
    <main style={{ maxWidth: "1500px", margin: "0 auto" }}>
      <CareerSecStyle>
        <div className="career-header">
          <h1>
            Careers With <span>TSE</span>
          </h1>
          <p>We don't just offer the job, we nurture the career.</p>
        </div>

        <div className="vacancy-container">
          {hirings.map((hiring) => (
            <VacancyCard key={hiring?._id} hiring={hiring}>
              <div className="job-description">
                <h4>Job Description</h4>
                <ul>
                  {hiring?.job_description?.map((job, i) => (
                    <li key={i}>{job}</li>
                  ))}
                </ul>
              </div>

              <div className="skills">
                <h4>Skills</h4>

                {hiring?.skillset?.map((skill, i) => (
                  <span key={i}>{skill}</span>
                ))}
              </div>

              <div className="profile-description">
                <h4>Profile Description</h4>

                <div className="profile-type">
                  <div className="type">
                    <h5>Industry Type</h5>
                  </div>
                  <div className="type-match">
                    <h5>IT Software</h5>
                  </div>
                </div>

                <div className="profile-type">
                  <div className="type">
                    <h5>Role</h5>
                  </div>
                  <div className="type-match">
                    <h5>{hiring?.role}</h5>
                  </div>
                </div>

                <div className="profile-type">
                  <div className="type">
                    <h5>Functional Area</h5>
                  </div>
                  <div className="type-match">
                    <h5>{hiring?.functional_area}</h5>
                  </div>
                </div>

                <div className="profile-type">
                  <div className="profile-type">
                    <div className="type">
                      <h5>Employment Type</h5>
                    </div>
                    <div className="type-match">
                      <h5>Full Time</h5>
                    </div>
                  </div>
                </div>
              </div>
            </VacancyCard>
          ))}
        </div>
      </CareerSecStyle>
      <CareerFormSecStyle>
        <div className="form-header">
          <h1>
            Feel Free <span>To Apply</span>
          </h1>
          <p>
            Please wait for our call if shortlisted, rather than calling us.
          </p>
        </div>

        <CareerFormContainerStyle id="apply">
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            method="post"
          >
            <div className="rows">
              <div className="input-control">
                <label htmlFor="position">
                  Position <span>*</span>
                </label>
                <select
                  name="position"
                  value={position}
                  onChange={handleOnChange}
                  required
                >
                  <option value="fullstack">Full Stack Developer (MERN)</option>
                  <option value="reactnative">React Native Developer</option>
                  <option value="reactjs">Front end Developer (ReactJS)</option>
                  <option value="laravel">PHP Laravel Developer</option>
                  <option value="java">Java Developer</option>
                  <option value="dotnet">Dot Net Developer</option>
                  <option value="android">Android Developer</option>
                  <option value="ios">iOS Developer</option>
                  <option value="tester">Software Tester</option>
                  <option value="ui/ux">UI/UX Designer</option>
                  <option value="graphic">Graphic Designer</option>
                </select>
              </div>

              <div className="input-control">
                <label htmlFor="experiance">
                  Experience (yrs)<span>*</span>
                </label>
                <select
                  name="experiance"
                  value={experiance}
                  onChange={handleOnChange}
                  required
                >
                  <option>Select</option>
                  <option value="fresher">Fresher</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10+">10+</option>
                </select>
              </div>
            </div>

            <div className="rows">
              <div className="input-control">
                <label htmlFor="first_name">
                  First Name <span>*</span>
                </label>
                <input
                  type="text"
                  required
                  name="first_name"
                  value={first_name}
                  onChange={handleOnChange}
                />
              </div>

              <div className="input-control">
                <label htmlFor="last_name">
                  Last Name <span>*</span>
                </label>
                <input
                  type="text"
                  required
                  name="last_name"
                  value={last_name}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="rows">
              <div className="input-control">
                <label htmlFor="mobile_no">
                  Mobile No.<span>*</span>
                </label>
                <input
                  type="text"
                  required
                  maxLength={10}
                  name="mobile_no"
                  value={mobile_no}
                  onChange={handleOnChange}
                />
              </div>

              <div className="input-control">
                <label htmlFor="email">
                  Email ID<span>*</span>
                </label>
                <input
                  type="email"
                  required
                  name="email"
                  value={email}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="rows">
              <div className="input-control">
                <label htmlFor="gender">
                  Gender<span>*</span>
                </label>
                <select
                  name="gender"
                  value={gender}
                  onChange={handleOnChange}
                  required
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>

              <div className="input-control">
                <label htmlFor="dob">
                  Date of Birth<span>*</span>
                </label>
                <input
                  type="date"
                  required
                  name="dob"
                  value={dob}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="rows">
              <div className="input-control">
                <label htmlFor="current_company">Current Company</label>
                <input
                  type="text"
                  name="current_company"
                  value={current_company}
                  onChange={handleOnChange}
                />
              </div>

              <div className="input-control">
                <label htmlFor="current_ctc">Current CTC(in Lacs)</label>
                <input
                  type="number"
                  name="current_ctc"
                  value={current_ctc}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="rows">
              <div className="input-control">
                <label htmlFor="notice_period">Notice Period(in days)</label>
                <select
                  name="notice_period"
                  value={notice_period}
                  onChange={handleOnChange}
                >
                  <option>Select</option>
                  <option value="immediate">Immediate</option>
                  <option value="15 days">15 days</option>
                  <option value="30 days">30 days</option>
                  <option value="45 days">45 days</option>
                  <option value="60 days">60 days</option>
                  <option value="75 days">75 days</option>
                  <option value="90 days">90 days</option>
                </select>
              </div>

              <div className="input-control">
                <label htmlFor="expected_ctc">Expected CTC</label>
                <input
                  type="number"
                  name="expected_ctc"
                  value={expected_ctc}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="rows">
              <div className="input-control">
                <label htmlFor="city">
                  Current City<span>*</span>
                </label>
                <input
                  type="text"
                  required
                  name="city"
                  value={city}
                  onChange={handleOnChange}
                />
              </div>

              <div className="input-control">
                <label htmlFor="address">
                  Address<span>*</span>
                </label>
                <input
                  type="text"
                  required
                  name="address"
                  value={address}
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="rows">
              <div className="input-control">
                <label htmlFor="qualification">
                  Qualification<span>*</span>
                </label>
                <select
                  name="qualification"
                  value={qualification}
                  onChange={handleOnChange}
                  required
                >
                  <option value="undergraduate">Under Graduate</option>
                  <option value="graduate">Graduate</option>
                  <option value="postgraduate">Post Graduate</option>
                </select>
              </div>

              <div className="input-control">
                <label htmlFor="cv_upload">
                  Attach CV(pdf)<span>*</span>
                </label>
                <input
                  type="file"
                  name="cv_upload"
                  // accept="application/pdf"
                  onChange={handleFileChange}
                  required
                />
              </div>
            </div>

            <div className="submit-btn">
              <button type="submit">
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </CareerFormContainerStyle>
      </CareerFormSecStyle>
    </main>
  );
};

export default Career;
