import styled from "styled-components";
import { motion } from "framer-motion";

export const BranchWrapperStyle = styled.section`
  color: white;

  .branch-header {
    background-color: #2d2d2d;
    border-bottom: 0.4rem solid #f44336;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 3rem;
    margin-top: 2rem;

    h2 {
      color: white;
      font-size: 3rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 1rem;
  }
`;

export const BranchContainerStyle = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  align-items: center;
  padding: 3rem 3rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BranchCardStyle = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  z-index: 5000;

  @media screen and (max-width: 768px) {
    z-index: unset;
  }

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.39) 0%,
    rgba(255, 255, 255, 0.09) 0.01%,
    rgba(255, 255, 255, 0.12) 100%
  );
  border: 0.2rem solid rgba(255, 255, 255, 0.39);
  backdrop-filter: blur(7.5px);
  border-radius: 1rem;

  .location-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    h3 {
      font-size: 2rem;
    }

    p {
      width: 20rem;
      margin-top: 1rem;
    }
  }

  .call-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem;

    .call {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .call:nth-of-type(2) {
      margin-top: 1rem;
      span {
        margin-left: 1rem;
      }
    }
  }

  .map-info {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const ContactFormWrapperStyle = styled.section`
  .contact-header {
    padding: 2rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    h2 {
      color: white;
      font-size: 3rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }
    }
  }
`;

export const FormContainerStyle = styled.div`
  padding: 2rem 3rem;
  width: 60rem;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 2rem 1rem;
    margin: auto;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .input-control {
      flex: 1 0 50%;
      margin: 1.5rem 2rem 0rem 0rem;
      position: relative;

      @media screen and (max-width: 768px) {
        margin: 1.5rem 0rem 0rem 0rem;
        width: 100%;
      }

      label {
        color: white;
        position: absolute;
        top: -10%;
        left: 9%;
        font-size: 0.8rem;
        background-color: black;
        padding: 0.1rem;
      }
      input {
        width: 100%;
        padding: 1.5rem 2rem;
        font-family: inherit;
        font-size: 1rem;
        border: 0.1rem solid rgba(255, 255, 255, 0.39);
        border-radius: 0.5rem;
        color: white;
        background: linear-gradient(
          183.35deg,
          rgba(65, 65, 65, 0) -1.12%,
          rgba(46, 46, 46, 0.6) 99.16%
        );

        &:focus {
          outline: none;
        }
      }
    }
  }

  .message-box {
    margin-top: 2.5rem;
    position: relative;

    label {
      color: white;
      position: absolute;
      top: -2%;
      left: 4.2%;
      font-size: 0.8rem;
      background-color: black;
      padding: 0.1rem;

      @media screen and (max-width: 768px) {
        left: 8.2%;
      }
    }

    textarea {
      width: 104%;
      padding: 1.5rem 2rem;
      border: 0.1rem solid rgba(255, 255, 255, 0.39);
      border-radius: 0.5rem;
      color: white;
      font-size: 1rem;
      background: linear-gradient(
        183.35deg,
        rgba(65, 65, 65, 0) -1.12%,
        rgba(46, 46, 46, 0.6) 99.16%
      );

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    button {
      width: 40%;
      border: none;
      font-family: inherit;
      padding: 1rem 3rem;
      font-size: 1.2rem;
      font-weight: bold;
      background-color: #f44336;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      border-radius: 0.5rem;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;

export const SaturnStyle = styled(motion.div)`
  position: absolute;
  /* top: 85%;
  left: -10%; */
  top: 10rem;
  left: -10rem;

  @media screen and (max-width: 768px) {
    display: none;
  }

  img {
    width: 50%;
    height: 50%;
    object-fit: cover;
  }
`;

export const MarsStyle = styled(motion.div)`
  position: absolute;
  /* top: 180%;
  right: -40%; */
  top: 70rem;
  right: -35rem;

  img {
    width: 40%;
    height: 40%;
    object-fit: cover;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
