import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  SidebarStyle,
  SidebarContainerStyle,
  NavLinkMobileStyle,
} from "./sidebar.style";

const Sidebar = ({ setIsOpen }) => {
  return (
    <SidebarStyle
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={() => setIsOpen((prevState) => !prevState)}
    >
      <SidebarContainerStyle
        initial={{ x: "-50%" }}
        animate={{ x: "0%" }}
        exit={{ x: "-50%" }}
        transition={{ type: "tween" }}
        onClick={(e) => e.stopPropagation()}
      >
        <NavLinkMobileStyle>
          <motion.li
            initial={{ opacity: 0, y: "100%" }}
            animate={{
              opacity: 1,
              y: "0%",
              transition: { duration: 0.35, delay: 0.2 },
            }}
            onClick={() => setIsOpen((prevState) => !prevState)}
          >
            <Link to="/">Home</Link>
          </motion.li>
          <motion.li
            initial={{ opacity: 0, y: "100%" }}
            animate={{
              opacity: 1,
              y: "0%",
              transition: { duration: 0.35, delay: 0.4 },
            }}
            onClick={() => setIsOpen((prevState) => !prevState)}
          >
            <Link to="/aboutus">About Us</Link>
          </motion.li>
          <motion.li
            initial={{ opacity: 0, y: "100%" }}
            animate={{
              opacity: 1,
              y: "0%",
              transition: { duration: 0.35, delay: 0.6 },
            }}
            onClick={() => setIsOpen((prevState) => !prevState)}
          >
            <Link to="/ourwork">Our Work</Link>
          </motion.li>
          <motion.li
            initial={{ opacity: 0, y: "100%" }}
            animate={{
              opacity: 1,
              y: "0%",
              transition: { duration: 0.35, delay: 0.8 },
            }}
            onClick={() => setIsOpen((prevState) => !prevState)}
          >
            <Link to="/ourgallery">Our Gallery</Link>
          </motion.li>
          <motion.li
            initial={{ opacity: 0, y: "100%" }}
            animate={{
              opacity: 1,
              y: "0%",
              transition: { duration: 0.35, delay: 1 },
            }}
            onClick={() => setIsOpen((prevState) => !prevState)}
          >
            <Link to="/career">Career</Link>
          </motion.li>
          <motion.li
            initial={{ opacity: 0, y: "100%" }}
            animate={{
              opacity: 1,
              y: "0%",
              transition: { duration: 0.35, delay: 1.2 },
            }}
            onClick={() => setIsOpen((prevState) => !prevState)}
          >
            <Link to="/contactus">Contact Us</Link>
          </motion.li>
        </NavLinkMobileStyle>
      </SidebarContainerStyle>
    </SidebarStyle>
  );
};

export default Sidebar;
