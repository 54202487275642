import React, { useState } from "react";
import { NavbarStyle, LogoStyle, NavLinkStyle } from "./navbar.style";
import { Link } from "react-router-dom";
import TSELogo from "../../assets/tse_logo.png";
import { Spin as Hamburger } from "hamburger-react";
import { Sidebar } from "../../components";
import { AnimatePresence } from "framer-motion";

const Navbar = () => {
  // eslint-disable-next-line
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  const [isOpen, setIsOpen] = useState(false);

  return (
    <NavbarStyle>
      <Link to="/">
        <LogoStyle>
          <img src={TSELogo} alt="tse logo" />
          <h4>
            The <span>Space</span> Element
          </h4>
        </LogoStyle>
      </Link>

      <NavLinkStyle>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/aboutus">About Us</Link>
        </li>
        <li>
          <Link to="/ourwork">Our Work</Link>
        </li>
        <li>
          <Link to="/ourgallery">Our Gallery</Link>
        </li>
        {/* <li>
          <Link to="/career">Career</Link>
        </li> */}
        <li>
          <div className="dropdown">
            <button className="dropbtn">Career</button>
            <div className="dropdown-content">
              <Link to="/career">Career with TSE</Link>
              <Link to="/training">Training with TSE</Link>
            </div>
          </div>
        </li>
        <li>
          <Link to="/contactus">Contact Us</Link>
        </li>
      </NavLinkStyle>

      {matches && (
        <div>
          <Hamburger
            color="#ffffff"
            rounded
            hideOutline={false}
            toggled={isOpen}
            toggle={setIsOpen}
            direction="right"
            label="Show menu"
          />
        </div>
      )}
      <AnimatePresence>
        {isOpen && <Sidebar setIsOpen={setIsOpen} />}
      </AnimatePresence>
    </NavbarStyle>
  );
};

export default Navbar;
