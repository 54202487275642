import React, { useState, useEffect } from "react";
import { CardContainerStyle, ViewDetailsStyle } from "./vacancycard.style";
import { SuitcaseIcon, MortarboardIcon } from "../../assets";

const VacancyCard = ({ children, hiring }) => {
  const [toggle, setToggle] = useState(false);

  // useEffect(() => {
  //   const onMove = () => {
  //     setToggle(false);
  //   };

  //   window.addEventListener("mousedown", onMove);
  //   return () => window.removeEventListener("mousedown", onMove);
  // }, []);

  return (
    <CardContainerStyle>
      <div className="vacancy-heading">
        <h2>{hiring?.designation_title}</h2>
        <a href="#apply">Apply Now</a>
      </div>
      <div className="divider-line"></div>
      <div className="vacancy-info">
        <div className="vacant-info-left">
          <div className="vacant">
            <img src={SuitcaseIcon} alt="Suitcase" />
            <span>{hiring?.experiance_level} year</span>
          </div>
          <div className="vacant">
            <img src={MortarboardIcon} alt="Mortarboard" />
            <span>{hiring?.qualification}</span>
          </div>
        </div>
        <div className="vacant-info-right">
          <a
            href="#details"
            onClick={() => setToggle((prevState) => !prevState)}
          >
            View Details
          </a>
        </div>
      </div>
      {toggle && <ViewDetailsStyle layout>{children}</ViewDetailsStyle>}
    </CardContainerStyle>
  );
};

export default VacancyCard;
