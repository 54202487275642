import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IntroSectionStyle,
  IntroLeftStyle,
  IntroRightStyle,
  IntroAstronautStyle,
  IntroSpaceshipStyle,
  WhatWeDoSecStyle,
  WhatWeDoLeftStyle,
  WhatWeDoRightStyle,
  WhatWeDoContainerStyle,
  TrustedSectiomnStyle,
  TrustedAstroBgStyle,
  TrustedMeteorBgStyle,
  WebDesignSecStyle,
  CustomDevSecStyle,
  AppDevSecStyle,
  SpreadSheetSecStyle,
  MarketingSecStyle,
  IoTSecStyle,
} from "./home.style";
import {
  PhotoshopIcon,
  XDIcon,
  FigmaIcon,
  TSEIcon,
  HTMLIcon,
  CSSIcon,
  JSIcon,
  BootstrapIcon,
  SASSIcon,
  ReactIcon,
  MongoIcon,
  SocketIcon,
  MysqlIcon,
  AWSIcon,
  NodeIcon,
  LaravelIcon,
} from "../../assets";
import techCircle from "../../assets/tech_circle.png";
import designIcon from "../../assets/design.png";
import developIcon from "../../assets/develop.png";
import appsIcon from "../../assets/Apps.png";
import labIcon from "../../assets/lab.png";
import trustedAstro from "../../assets/trusted_astronaut.png";
import meteorBg from "../../assets/meteor.png";
import IntroAstronautLottie from "../../assets/intro-astronaut.json";
import SpaceshipLottie from "../../assets/spaceship.json";
import WebsiteDesignLottie from "../../assets/website-design-lottie.json";
import CustomDevLottie from "../../assets/custom-dev-lottie.json";
import AppDevLottie from "../../assets/app-dev-lottie.json";
import SpreadsheetLottie from "../../assets/spreadsheet-lottie.json";
import MarketingLottie from "../../assets/marketing-two.json";
import IOTLottie from "../../assets/IOT-lottie.json";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import {
  ANCLogo,
  AccountingLogo,
  AkLogo,
  BalisCafeLogo,
  CebaLogo,
  EatKitLogo,
  EmpresCityLogo,
  EmpressKidsLogo,
  HDFCLogo,
  JACLogo,
  LLPLogo,
  NagarjunLogo,
  PRLogo,
  RSICoralLogo,
  RSIHouseLogo,
  ShreejiLogo,
  SiddhiLogo,
  VividLogo,
  YummyShummyLogo,
} from "../../assets";

const introAstronautOptions = {
  loop: true,
  autoplay: true,
  animationData: IntroAstronautLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const spaceshipOptions = {
  loop: true,
  autoplay: true,
  animationData: SpaceshipLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const websiteDesignOptions = {
  loop: true,
  autoplay: true,
  animationData: WebsiteDesignLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const customDevelopmentOptions = {
  loop: true,
  autoplay: true,
  animationData: CustomDevLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const appDevelopmentOptions = {
  loop: true,
  autoplay: true,
  animationData: AppDevLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const spreadSheetOptions = {
  loop: true,
  autoplay: true,
  animationData: SpreadsheetLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const marketingOptions = {
  loop: true,
  autoplay: true,
  animationData: MarketingLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const iotOptions = {
  loop: true,
  autoplay: true,
  animationData: IOTLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Home = () => {
  const logosArray = [
    ANCLogo,
    AccountingLogo,
    AkLogo,
    BalisCafeLogo,
    CebaLogo,
    EatKitLogo,
    EmpresCityLogo,
    EmpressKidsLogo,
    HDFCLogo,
    JACLogo,
    LLPLogo,
    NagarjunLogo,
    PRLogo,
    RSICoralLogo,
    RSIHouseLogo,
    ShreejiLogo,
    SiddhiLogo,
    VividLogo,
    YummyShummyLogo,
  ];

  const navigate = useNavigate();

  // eslint-disable-next-line
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main
      style={{
        maxWidth: "1500px",
        paddingRight: "calc(var(--bs-gutter-x) * 0.5)",
        paddingLeft: "calc(var(--bs-gutter-x) * 0.5)",
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      <IntroSectionStyle>
        <IntroLeftStyle
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
        >
          {matches ? (
            <h1>
              We Design & <br /> Code
            </h1>
          ) : (
            <>
              <h1>We Design</h1>
              <h1>&</h1>
              <h1>Code</h1>
            </>
          )}

          <p>We Build Custom Websites, Mobile Apps & Softwares.</p>

          <div className="intro-btns">
            <button onClick={() => navigate("/contactus")}>
              Request a quote
            </button>
            <button onClick={() => navigate("/ourwork")}>Know more</button>
          </div>

          {matches && (
            <img src={techCircle} alt="Technologies" className="tech-circle" />
          )}
        </IntroLeftStyle>
        <IntroAstronautStyle
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
        >
          <Lottie options={introAstronautOptions} height={350} width={350} />
        </IntroAstronautStyle>
        <IntroSpaceshipStyle
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
        >
          <Lottie options={spaceshipOptions} height={100} width={100} />
        </IntroSpaceshipStyle>
        <IntroRightStyle
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
        >
          <img src={TSEIcon} alt="TSE Logo" className="tse-logo" />

          <motion.div
            whileInView={{ rotate: [0, 360] }}
            whileHover={{ rotate: [0, 0] }}
            transition={{ ease: "linear", duration: 15, repeat: Infinity }}
            className="circle-one"
          >
            <div className="photoshop">
              <img src={PhotoshopIcon} alt="Photoshop" />
              <span className="photoshop-title">Photoshop</span>
            </div>

            <div className="xd">
              <img src={XDIcon} alt="Adobe XD" />
              <span className="xd-title">Adobe XD</span>
            </div>

            <div className="figma">
              <img src={FigmaIcon} alt="Figma" />
              <span className="figma-title">Figma</span>
            </div>
          </motion.div>

          <motion.div
            whileInView={{ rotate: [0, 360] }}
            whileHover={{ rotate: [0, 0], transition: { delay: 0 } }}
            transition={{
              ease: "linear",
              duration: 15,
              delay: 2,
              repeat: Infinity,
            }}
            className="circle-two"
          >
            <div className="html">
              <img src={HTMLIcon} alt="HTML" />
              <span className="html-title">HTML5</span>
            </div>

            <div className="css">
              <img src={CSSIcon} alt="Cascading style sheet" />
              <span className="css-title">CSS3</span>
            </div>

            <div className="js">
              <img src={JSIcon} alt="Javascript" />
              <span className="js-title">Javascript</span>
            </div>

            <div className="bootstrap">
              <img src={BootstrapIcon} alt="Bootstrap" />
              <span className="bootstrap-title">Bootstrap</span>
            </div>

            <div className="sass">
              <img src={SASSIcon} alt="Sass" />
              <span className="sass-title">SASS</span>
            </div>

            <div className="react">
              <img src={ReactIcon} alt="React" />
              <span className="react-title">React JS</span>
            </div>
          </motion.div>

          <motion.div
            whileInView={{ rotate: [0, 360] }}
            whileHover={{ rotate: [0, 0], transition: { delay: 0 } }}
            transition={{
              ease: "linear",
              duration: 15,
              delay: 3,
              repeat: Infinity,
            }}
            className="circle-three"
          >
            <div className="mongo">
              <img src={MongoIcon} alt="MongoDB" />
              <span className="mongo-title">Mongo DB</span>
            </div>

            <div className="socket">
              <img src={SocketIcon} alt="Socket IO" />
              <span className="socket-title">Socket IO</span>
            </div>

            <div className="aws">
              <img src={AWSIcon} alt="AWS" />
              <span className="aws-title">AWS</span>
            </div>

            <div className="mysql">
              <img src={MysqlIcon} alt="Mysql" />
              <span className="mysql-title">Mysql</span>
            </div>

            <div className="laravel">
              <img src={LaravelIcon} alt="Laravel" />
              <span className="laravel-title">Laravel</span>
            </div>

            <div className="node">
              <img src={NodeIcon} alt="Node JS" />
              <span className="node-title">Node JS</span>
            </div>
          </motion.div>
        </IntroRightStyle>
      </IntroSectionStyle>
      {/* What we DO */}
      <WhatWeDoSecStyle>
        <WhatWeDoContainerStyle>
          <div className="overlay-bg"></div>
          <WhatWeDoLeftStyle
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.75, delay: 0.2 }}
          >
            <div className="row-one">
              <div className="design">
                <img src={designIcon} alt="design" />
                <h3>Design</h3>
              </div>
              <div className="develop">
                <img src={developIcon} alt="develop" />
                <h3>Develop</h3>
              </div>
            </div>
            <div className="row-two">
              <div className="apps">
                <img src={appsIcon} alt="apps" />
                <h3>Apps</h3>
              </div>
              <div className="lab">
                <img src={labIcon} alt="lab" />
                <h3>Lab</h3>
              </div>
            </div>
          </WhatWeDoLeftStyle>
          <WhatWeDoRightStyle
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.75, delay: 0.2 }}
          >
            <h2>What we do?</h2>
            <p>
              <span>The Space Element</span> is all-in-one design & development
              in three core verticals: Design,Web Development & Software
              Solutions. We continue to provide best solutions to our clients
              and contribute to open source with our practices.
            </p>
          </WhatWeDoRightStyle>
        </WhatWeDoContainerStyle>
      </WhatWeDoSecStyle>
      {/* Trusted Brands  */}
      <TrustedSectiomnStyle>
        <h2>Trusted By Businesses & Brands</h2>
        <TrustedAstroBgStyle
          initial={{ translateY: 0 }}
          animate={{ translateY: 10 }}
          transition={{ duration: 5, repeat: Infinity }}
        >
          <img src={trustedAstro} alt="astronaut" />
        </TrustedAstroBgStyle>
        <TrustedMeteorBgStyle
          initial={{ translateY: 0 }}
          animate={{ translateY: 10 }}
          transition={{ duration: 5, repeat: Infinity }}
        >
          <img src={meteorBg} alt="Meteor" />
        </TrustedMeteorBgStyle>
        <Swiper
          slidesPerView={5}
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          breakpoints={{
            375: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
            999: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            280: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
          }}
        >
          {logosArray.map((logo, index) => (
            <SwiperSlide key={index}>
              <div className="trust-card">
                <img src={logo} alt={index} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </TrustedSectiomnStyle>
      {/* Creativity Section */}
      {/* Website Design Section */}
      <WebDesignSecStyle>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
          className="web-design-lottie"
        >
          <Lottie options={websiteDesignOptions} height="100%" width="100%" />
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
          className="website-design-info"
        >
          <h2>Website Design</h2>

          <p>
            By utilizing elements and principles of Design and Usability, we are
            able to create solutions, that help you represent your brand
            uniquely to the world.
          </p>
        </motion.div>
      </WebDesignSecStyle>
      {/* Custom Development Section */}
      <CustomDevSecStyle>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
          className="custom-dev-info"
        >
          <h2>Custom Development</h2>

          <p>
            We do most of the heavy lifting for you, from research to providing
            a solution for most of your unique and challenging needs.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
          className="custom-dev-lottie"
        >
          <Lottie
            options={customDevelopmentOptions}
            height="100%"
            width="100%"
          />
        </motion.div>
      </CustomDevSecStyle>
      <AppDevSecStyle>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
          className="app-dev-lottie"
        >
          <Lottie options={appDevelopmentOptions} height="100%" width="100%" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
          className="app-dev-info"
        >
          <h2>App Development</h2>

          <p>
            The everchanging mobile landscape demands scalable and stable
            solution to work on various platforms. We help you achieve this with
            various proven technologies for a long term solution.
          </p>
        </motion.div>
      </AppDevSecStyle>
      {/* Spreadsheet Section */}
      <SpreadSheetSecStyle>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
          className="spreadsheet-info"
        >
          <h2>Spreadsheet Solutions</h2>

          <p>
            Not every problem needs a custom software or website from ground-up,
            that's why we provide these initial solutions for your business
            which helps you better manage & visualize on powerful dashboard with
            the convenience of your existing spreadsheets.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
          className="spreadsheet-lottie"
        >
          <Lottie options={spreadSheetOptions} height="100%" width="100%" />
        </motion.div>
      </SpreadSheetSecStyle>
      {/* Online Marketing */}
      <MarketingSecStyle>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
          className="marketing-lottie"
        >
          <Lottie options={marketingOptions} height="100%" width="100%" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
          className="marketing-info"
        >
          <h2>Online Marketing</h2>

          <p>
            Our marketing team is well equipped with providing you solutions to
            increase your market exposure, and communicate with your audience
            with ease.
          </p>
        </motion.div>
      </MarketingSecStyle>
      {/* IOT Section */}
      <IoTSecStyle>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
          className="iot-info"
        >
          <h2>IoT Solutions</h2>

          <p>
            We craft custom automation, monitoring and system control solutions.
            With off the shelf technologies like Arduino, BegalBone,
            RasberryPie, PIC, etc.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
          className="iot-lottie"
        >
          <Lottie options={iotOptions} height="100%" width="100%" />
        </motion.div>
      </IoTSecStyle>
    </main>
  );
};

export default Home;
