import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import axios from "axios";
import { APIS, IMG_URL } from "../../utils/Constants";
import {
  AppHeaderWrapperStyle,
  MobileWrapperStyle,
  MobileContainerStyle,
} from "./projectdetailapp.style";

const ProjectDetailApp = () => {
  const { id } = useParams();

  const [appDetails, setAppDetails] = useState();

  const fetchAppDetails = async () => {
    try {
      if (id) {
        const response = await axios.get(
          APIS.getURLS.getSingleApp + "/getappdetails/" + id
        );

        if (response.data.status === "success") {
          setAppDetails(response.data.app_details);
        }
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAppDetails();
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <AppHeaderWrapperStyle>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75 }}
          className="project-header"
        >
          <h2>{appDetails?.project_title}</h2>
          <p>{appDetails?.project_description}</p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
          className="group-screen"
        >
          <img
            src={IMG_URL + "/" + appDetails?.heroImg}
            alt={appDetails?.project_title}
          />
        </motion.div>
      </AppHeaderWrapperStyle>

      <MobileWrapperStyle>
        <div className="mobile-header">
          <h2>App</h2>
        </div>

        <MobileContainerStyle>
          <div className="mob-row-one">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75 }}
              className="mobile-img"
            >
              <img
                src={IMG_URL + "/" + appDetails?.AppSnapImg[0]}
                alt={appDetails?.project_title}
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75, delay: 0.2 }}
              className="mobile-img"
            >
              <img
                src={IMG_URL + "/" + appDetails?.AppSnapImg[1]}
                alt={appDetails?.project_title}
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75, delay: 0.3 }}
              className="mobile-img"
            >
              <img
                src={IMG_URL + "/" + appDetails?.AppSnapImg[2]}
                alt={appDetails?.project_title}
              />
            </motion.div>
          </div>

          <div className="mob-row-two">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75, delay: 0.4 }}
              className="mobile-img"
            >
              <img
                src={IMG_URL + "/" + appDetails?.AppSnapImg[3]}
                alt={appDetails?.project_title}
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75, delay: 0.5 }}
              className="mobile-img"
            >
              <img
                src={IMG_URL + "/" + appDetails?.AppSnapImg[4]}
                alt={appDetails?.project_title}
              />
            </motion.div>
          </div>
        </MobileContainerStyle>
      </MobileWrapperStyle>
    </main>
  );
};

export default ProjectDetailApp;
