import styled from "styled-components";

export const NavbarStyle = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 10vh;
  padding: 0rem 3rem;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 8000;

  @media screen and (max-width: 768px) {
    padding: 0rem 1rem;
  }
  background: linear-gradient(to right, #383838 0%, #232323 100%);
`;

export const LogoStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;

  img {
    width: 3rem;
    height: 3rem;
    object-fit: cover;
  }

  h4 {
    color: #ffffff;

    span {
      display: inline-block;
      color: #ec2024;
    }
  }
`;

export const NavLinkStyle = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }

  li {
    margin: 0rem 2rem 0rem 0rem;
  }

  a {
    color: #ffffff;
    font-weight: bold;
  }
`;
