import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import IntroAstronautLottie from "../../assets/intro-astronaut.json";
import SpaceshipLottie from "../../assets/spaceship.json";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import {
  FooterWrapperStyle,
  FooterLogoStyle,
  SitemapLinkStyle,
  SocialLinksStyle,
  FooterLeftStyle,
  FooterRightStyle,
  BorderStyle,
  AstronautStyle,
  SpaceshipStyle,
} from "./footer.style";
import footerLogo from "../../assets/footer-logo-tse.png";
import facebookIcon from "../../assets/facebook_footer.png";
import instagramIcon from "../../assets/instagram_footer.png";
import linkedinIcon from "../../assets/linkedin_footer.png";
import twitterIcon from "../../assets/twitter.png";

const introAstronautOptions = {
  loop: true,
  autoplay: true,
  animationData: IntroAstronautLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const spaceshipOptions = {
  loop: true,
  autoplay: true,
  animationData: SpaceshipLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Footer = () => {
  return (
    <Fragment>
      <FooterWrapperStyle
      // style={{
      //   maxWidth: "1500px",
      //   paddingRight: "calc(var(--bs-gutter-x) * 0.5)",
      //   paddingLeft: "calc(var(--bs-gutter-x) * 0.5)",
      //   marginRight: "auto",
      //   marginLeft: "auto",
      // }}
      >
        <FooterLeftStyle>
          <FooterLogoStyle>
            <img src={footerLogo} alt="logo" />
            <h3>
              The <span>Space</span> Element
            </h3>
          </FooterLogoStyle>
          <SitemapLinkStyle>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/contactus">Contact Us</Link>
              </li>
              <li>
                <Link to="/career">Career</Link>
              </li>
              <li>
                <Link to="/ourwork">Our Work</Link>
              </li>
              <li>
                <Link to="/contactus">Our Branches</Link>
              </li>
            </ul>
          </SitemapLinkStyle>

          <div className="sitemap-two">
            <ul>
              <li>
                <Link to="/aboutus">Testimonials</Link>
              </li>
              <li>
                <a href="/TermsAndCondition.html">Terms and Conditions</a>
              </li>
              <li>
                <a href="/Privacy.html">Privacy Policy</a>
              </li>
              <li>
                <a href="/ReturPolicy.html">Return Policy</a>
              </li>
              <li>
                <a href="/ShippingPolicy.html">Shipping Policy</a>
              </li>
            </ul>
          </div>
        </FooterLeftStyle>
        <FooterRightStyle>
          <div>
            <AstronautStyle>
              <Lottie
                options={introAstronautOptions}
                height={300}
                width={300}
              />
            </AstronautStyle>
            <SpaceshipStyle>
              <Lottie options={spaceshipOptions} height={100} width={100} />
            </SpaceshipStyle>
          </div>
          <SocialLinksStyle>
            <h5>Connect With Us</h5>
            <div>
              <motion.a
                whileHover={{ scaleY: 1.1 }}
                href="https://www.facebook.com/TheSpaceElement"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebookIcon} alt="facebook" />
              </motion.a>
              <motion.a
                whileHover={{ scaleY: 1.1 }}
                href="https://www.instagram.com/TheSpaceElement/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagramIcon} alt="instagram" />
              </motion.a>
              <motion.a
                whileHover={{ scaleY: 1.1 }}
                href="https://www.linkedin.com/company/the-space-element/mycompany/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedinIcon} alt="linkedin" />
              </motion.a>
              <motion.a
                whileHover={{ scaleY: 1.1 }}
                href="https://twitter.com/TheSpaceElement"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitterIcon} alt="twitter" />
              </motion.a>
            </div>
          </SocialLinksStyle>
        </FooterRightStyle>
      </FooterWrapperStyle>
      <BorderStyle>
        <div className="line"></div>
        <h3>Made with ❤️ in India</h3>
      </BorderStyle>
    </Fragment>
  );
};

export default Footer;
