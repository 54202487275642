import React, { useEffect, useState } from "react";
import image1 from "../../assets/image1.png";
import img2 from "../../assets/career2.png";
import "./training_tse.css";
import prog1 from "../../assets/program1.png";
import prog2 from "../../assets/program2.png";
import prog3 from "../../assets/program3.png";
import axios from "axios";
import { APIS } from "../../utils/Constants";
import { toast } from "react-hot-toast";
import fullstackpdf from "../../assets/Full Stack Developer Brochure.pdf";
import pythonpdf from "../../assets/Python Training Program Broucher.pdf";
import sappdf from "../../assets/SAP Brochure.pdf";

const Careernew1 = () => {
  const trainingObj = {
    full_name: "",
    mobile_no: "",
    email: "",
    training: "",
    msg_description: "",
  };

  const [getallTrainings, setgetallTrainings] = useState(trainingObj);
  const [allTrainings, setallTrainings] = useState([]);
  const [loading, setLoading] = useState(false);

  const { full_name, mobile_no, email, training, msg_description } =
    getallTrainings;

  const getTraining = () => {
    axios.get(APIS.getURLS.getAllTraining).then((res) => {
      console.log(res.data.data.docs);
      setallTrainings(res.data.data.docs);
    });
  };

  useEffect(() => {
    getTraining();
    window.scrollTo(0, 0);
  }, []);

  const clearFormFields = () => {
    setgetallTrainings((prevState) => ({
      ...prevState,
      full_name: "",
      mobile_no: "",
      email: "",
      training: "",
      msg_description: "",
    }));
  };

  const validateInputs = () => {
    let validateName = /^[A-Za-z.-]+(\s*[A-Za-z.-]+)*$/;
    let validateEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
    let validateMobNo =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

    if (full_name === "") {
      toast.error("Please enter your First Name");
    } else if (!validateName.test(full_name)) {
      setLoading(false);

      toast.error("Please enter valid name");
    }

    if (email === "") {
      toast.error("Please enter your email");
    } else if (!validateEmail.test(email)) {
      setLoading(false);

      toast.error("Please enter valid email");
    }

    if (mobile_no === "") {
      toast.error("Please enter your mobile no");
    } else if (!validateMobNo.test(mobile_no)) {
      setLoading(false);

      toast.error("Please enter valid mobile no");
    }

    if (training === "") {
      toast.error("Please enter your training name");
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const payLoad = {
      name: full_name,
      mobile_no: mobile_no,
      email: email,
      trainig_course: training,
      message: msg_description,
    };

    // console.log(payLoad);
    // return;

    try {
      validateInputs();

      const response = await axios.post(APIS.postURLS.createTrainings, payLoad);

      if (response.data.status === "success") {
        setLoading(false);
        toast.success("Thank you for getting in touch!");
        clearFormFields();
      } else if (response.data.status === "failed") {
        setLoading(false);
        toast.error("Unable to save contact");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (e) => {
    console.log(e.target.value);
    setgetallTrainings((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="container frame m-0 p-0">
      <div className="training row m-0 p-0 d-flex justify-content-between">
        <div className="col-lg-5 m-0 p-0 col-sm-12 col-md-12">
          <img src={image1} alt="no img" className="img1 img2" />
        </div>
        <div className="content1 col-lg-6 m-0 p-0 col-sm-12 col-md-12">
          <h1 className="heading1">Why Join this training?</h1>
          <p className="description m-0 p-0">
            The Space Element offers Best Training and Internship Program in
            Nagpur. We offer classroom training, online training, and a
            fast-track course. Students can choose from a range of course
            schedules to suit their needs. Our trainers have at least 10 years
            of expertise in implementing technologies. Trainers are real-time
            working consultants who provide hands-on training based on
            real-world circumstances.
          </p>
        </div>
      </div>

      <div className="training row m-0 p-0 d-flex justify-content-between flex-wrap-reverse mt-5">
        <div className="content1 col-lg-6 m-0 p-0 col-sm-12 col-md-12">
          <h1 className="heading1">Who can apply?</h1>
          <p className="description">
            The Space Element training program is open to all. The only criteria
            is you must be passionate about learning. There are no specific
            prerequisites in terms of educational background or prior
            experience. You can be a student, professional, or person seeking to
            change career, etc.
          </p>
        </div>
        <div className="col-lg-5 m-0 p-0 col-sm-12 col-md-12">
          <img src={img2} alt="no img" className="img1 img2" />
        </div>
      </div>

      <div className="programheading m-0 p-0">
        <p>Start your career with our Training Program</p>
      </div>

      <div className="program">
        <div className="box">
          <div className="program1 m-0 p-0">
            <a href={fullstackpdf} target="_blank" rel="noreferrer">
              <img src={prog1} alt="no img" />
            </a>
          </div>
          <div className="progTitle1 m-0 p-0">
            <p>
              Full Stack Developer Training
              <br /> Program
            </p>
          </div>
        </div>

        <div className="box">
          <div className="program1 m-0 p-0">
            <a href={pythonpdf} target="_blank" rel="noreferrer">
              <img src={prog2} alt="no img" />
            </a>
          </div>
          <div className="progTitle1 m-0 p-0">
            <p>Python Training Program </p>
          </div>
        </div>

        <div className="box m-0 p-0">
          <div className="program1">
            <a href={sappdf} target="_blank" rel="noreferrer">
              <img src={prog3} alt="no img" />
            </a>
          </div>
          <div className="progTitle1 m-0 p-0">
            <p>SAP Training Program </p>
          </div>
        </div>
      </div>

      <div className="programheading m-0 p-0 case1">
        <p>In case of any inquiry, please let us know</p>
      </div>

      <form onSubmit={handleOnSubmit}>
        <div className="form1">
          <div className="row d-flex input1 m-0 p-0 mt-lg-3">
            <div className="col-lg-6 col-md-12 col-sm-12 m-0 pe-3 text-lg-end field mt-md-4">
              <div className="input-control">
                <input
                  type="text"
                  name="full_name"
                  className="name"
                  placeholder="Full Name"
                  // value={full_name}
                  onChange={handleOnChange}
                  required
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 m-0 pe-3 text-lg-start field mt-md-4">
              <div className="input-control">
                <input
                  type="text"
                  name="mobile_no"
                  className="name"
                  placeholder="Mobile Number"
                  required
                  maxLength={10}
                  value={mobile_no}
                  onChange={handleOnChange}
                />
              </div>
            </div>
          </div>

          <div className="row d-flex input1 m-0 p-0 mt-lg-3">
            <div className="col-lg-6 col-md-12 col-sm-12 m-0 pe-lg-3 text-lg-end field mt-md-4">
              <div className="input-control">
                <input
                  type="email"
                  name="email"
                  className="name"
                  placeholder="Email"
                  value={email}
                  required
                  onChange={handleOnChange}
                />
              </div>
            </div>

            <div className="col-lg-6 col-sm-12 col-md-12 m-0 pe-lg-3 text-lg-start field  mt-md-4">
              <div className="input-control">
                <select
                  name="training"
                  className="name"
                  onChange={handleOnChange}
                  value={getallTrainings?.training}
                >
                  {allTrainings?.map?.((training, i) => (
                    <option
                      key={i}
                      value={training?.title}
                      style={{ color: "black" }}
                    >
                      {training.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="input1 text-center mt-lg-5 field1 mt-md-4">
            <div className="input-control">
              <input
                type="text"
                placeholder="Message"
                name="msg_description"
                className="textfield name"
                cols="30"
                rows="10"
                value={msg_description}
                onChange={handleOnChange}
                style={{ paddingBottom: "20%", width: "87.5%" }}
              />
            </div>
          </div>

          <div className="col-12 m-0 p-0 text-center mt-5">
            <button className="submitbtn" type="submit">
              {loading ? "Submitting..." : "SUBMIT"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Careernew1;
