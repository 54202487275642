import styled from "styled-components";

export const CareerSecStyle = styled.section`
  padding: 3rem 3rem;

  @media screen and (max-width: 768px) {
    padding: 3rem 1rem;
  }

  .career-header {
    text-align: center;
    h1 {
      color: white;
      font-size: 3rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }

      span {
        display: inline-block;
        color: #ec2024;
      }
    }

    p {
      margin-top: 1rem;
      color: white;
      text-align: center;

      @media screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }
  }

  .vacancy-container {
    margin-top: 2rem;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const CareerFormSecStyle = styled.section`
  padding: 2rem 3rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }

  .form-header {
    text-align: center;
    h1 {
      color: white;
      font-size: 3rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }

      span {
        display: inline-block;
        color: #ec2024;
      }
    }

    p {
      margin-top: 1rem;
      color: white;
      text-align: center;

      @media screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }
  }
`;

export const CareerFormContainerStyle = styled.div`
  margin-top: 2rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.39) 0%,
    rgba(255, 255, 255, 0.09) 0.01%,
    rgba(255, 255, 255, 0.12) 100%
  );
  border: 0.1rem solid rgba(255, 255, 255, 0.39);
  border-radius: 1rem;
  padding: 2rem;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

  .rows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    margin: 2rem 0rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .input-control {
      position: relative;
      flex: 1;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      label {
        color: white;
        position: absolute;
        top: -14%;
        left: 6%;
        font-size: 0.8rem;
        background-color: #171717;
        padding: 0.1rem;

        span {
          display: inline-block;
          color: red;
        }
      }

      input {
        width: 100%;
        padding: 1.5rem 2rem;
        font-family: inherit;
        font-size: 1rem;
        border: 0.1rem solid rgba(255, 255, 255, 0.39);
        border-radius: 0.5rem;
        color: white;
        background: linear-gradient(
          183.35deg,
          rgba(65, 65, 65, 0) -1.12%,
          rgba(46, 46, 46, 0.6) 99.16%
        );

        &:focus {
          outline: none;
        }
      }

      input[type="file"] {
        direction: rtl;

        &::-webkit-file-upload-button {
          background: #ec2024;
          border: none;
          color: white;
          padding: 0.2rem 1rem;
        }
      }

      input[type="date"]::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }

      select {
        width: 100%;
        padding: 1.5rem 2rem;
        font-family: inherit;
        font-size: 1rem;
        border: 0.1rem solid rgba(255, 255, 255, 0.39);
        border-radius: 0.5rem;
        color: #878787;
        background: linear-gradient(
          183.35deg,
          rgba(65, 65, 65, 0) -1.12%,
          rgba(46, 46, 46, 0.6) 99.16%
        );
        -webkit-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    button {
      width: 40%;
      border: none;
      font-family: inherit;
      padding: 1rem 3rem;
      font-size: 1.2rem;
      font-weight: bold;
      background-color: #f44336;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      border-radius: 0.5rem;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;
