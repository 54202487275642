import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  ProjectHeaderWrapperStyle,
  WebsiteWrapperStyle,
  WebContainerStyle,
  MobileWrapperStyle,
  MobileContainerStyle,
} from "./projectdetail.style";
import { useParams } from "react-router-dom";
import axios from "axios";
import { APIS, IMG_URL } from "../../utils/Constants";

const ProjectDetail = () => {
  const { id } = useParams();

  const [webDetail, setWebDetail] = useState();

  const fetchWebDetails = async () => {
    try {
      if (id) {
        const response = await axios.get(
          APIS.getURLS.getSingleWeb + "/getwebdetails/" + id
        );

        if (response.data.status === "success") {
          setWebDetail(response.data.web_details);
        }
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchWebDetails();
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <ProjectHeaderWrapperStyle>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75 }}
          className="project-header"
        >
          <h2>{webDetail?.project_title}</h2>
          <p>{webDetail?.project_description}</p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
          className="group-screen"
        >
          <img
            src={IMG_URL + "/" + webDetail?.heroImg}
            alt={webDetail?.project_title}
          />
        </motion.div>
      </ProjectHeaderWrapperStyle>

      <WebsiteWrapperStyle>
        <div className="website-header">
          <h2>Website</h2>
        </div>
        <WebContainerStyle>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.75 }}
            className="web-one"
          >
            <img
              src={IMG_URL + "/" + webDetail?.websnapImg}
              alt={webDetail?.project_title}
            />
          </motion.div>
          {/* <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.75, delay: 0.2 }}
            className="web-two"
          >
            <img src={detailIinfo[0].webTwo} alt={detailIinfo[0].webTwo} />
          </motion.div> */}
        </WebContainerStyle>
      </WebsiteWrapperStyle>

      <MobileWrapperStyle>
        <div className="mobile-header">
          <h2>Mobile Responsive</h2>
        </div>

        <MobileContainerStyle>
          <div className="mob-row-one">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75 }}
              className="mobile-img"
            >
              <img
                src={IMG_URL + "/" + webDetail?.appsnapImg[0]}
                alt={webDetail?.project_title}
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75, delay: 0.2 }}
              className="mobile-img"
            >
              <img
                src={IMG_URL + "/" + webDetail?.appsnapImg[1]}
                alt={webDetail?.project_title}
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75, delay: 0.3 }}
              className="mobile-img"
            >
              <img
                src={IMG_URL + "/" + webDetail?.appsnapImg[2]}
                alt={webDetail?.project_title}
              />
            </motion.div>
          </div>

          <div className="mob-row-two">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75, delay: 0.4 }}
              className="mobile-img"
            >
              <img
                src={IMG_URL + "/" + webDetail?.appsnapImg[3]}
                alt={webDetail?.project_title}
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75, delay: 0.5 }}
              className="mobile-img"
            >
              <img
                src={IMG_URL + "/" + webDetail?.appsnapImg[4]}
                alt={webDetail?.project_title}
              />
            </motion.div>
          </div>
        </MobileContainerStyle>
      </MobileWrapperStyle>
    </main>
  );
};

export default ProjectDetail;
