import React, { useEffect, useState } from "react";
import {
  WhySuccessWrapperStyle,
  ManageSecStyle,
  ManageOneContainer,
  OurVisionStyle,
  TestimonialsSecStyle,
  ReviewsContainerStyle,
} from "./aboutus.style";
import { ProfileImg, SwapnilImg, ManishImg } from "../../assets";
import { Autoplay } from "swiper";
import axios from "axios";
import { APIS, IMG_URL } from "../../utils/Constants";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { motion } from "framer-motion";

const AboutUs = () => {
  const [testimonials, setTestimonials] = useState([]);

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get(APIS.getURLS.getAllTestimonials);
      console.log(response);
      if (response.data.status === "success") {
        setTestimonials(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchTestimonials();
  }, []);

  return (
    <main
      style={{
        maxWidth: "1500px",
        paddingRight: "calc(var(--bs-gutter-x) * 0.5)",
        paddingLeft: "calc(var(--bs-gutter-x) * 0.5)",
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      <WhySuccessWrapperStyle>
        <div className="overlay"></div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
          className="why-success"
        >
          <h1 style={{ textAlign: 'center' }}>
            Why <span>TSE</span>
          </h1>

          <p>
            We at The Space Element strive to work on the latest technologies,
            research and inventions. Technology can do some things better than
            people, we love to try out new things and experiment with various
            technologies to effectively design and develop custom fit solutions
            for any of your business need.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
          className="why-success story"
        >
          <h1 style={{ textAlign: 'center' }}>
            Success <span>Story</span>
          </h1>

          <p>
            Once upon a time, in the Nagpur city of Maharashtra, India, a visionary IT company called The Space Element emerged with a mission to redefine the possibilities of technology. With a dedicated team of tech enthusiasts and industry experts, they set out to create a success story that would inspire the entire nation.
          </p>
          <p>
            From the very beginning, The Space Element prioritized innovation and quality as their guiding principles. They understood that to stand out in the highly competitive IT landscape, they needed to deliver exceptional solutions that exceeded client expectations. With this mindset, they began developing cutting-edge software products and providing bespoke IT services to businesses across various industries.
          </p>
          <p>
            Building on their success, The Space Element expanded their portfolio to include a wide range of IT services, catering to the diverse needs of their clients. Their skilled team of engineers, designers, and consultants worked tirelessly to deliver tailor-made solutions, leveraging the latest technologies such as Machine Learning, Augmented Reality, and Artificial Intelligence.
          </p>
          <p>
            One of the key factors behind The Space Element's success was their focus on nurturing talent and fostering a culture of innovation. They recognized the importance of investing in their employees, offering continuous learning opportunities, and encouraging them to push the boundaries of their skills. This resulted in a team of highly motivated individuals who consistently delivered top-notch solutions.
          </p>
          <p>
            Another pillar of The Space Element's success was their unwavering commitment to customer satisfaction. They firmly believed that building strong, long-term relationships with their clients was essential for mutual growth. They listened to their clients' needs, collaborated closely throughout the project lifecycle, and provided unparalleled support even after the completion of their services.
          </p>
          <p>
            As The Space Element continued to flourish, they remained committed to giving back to society. They actively participated in social initiatives, supporting education, environmental conservation, and other noble causes. By leveraging their technological expertise, they empowered underprivileged communities, bridged the digital divide, and made a positive impact on society.
          </p>
          <p>
            Today, The Space Element stands proudly as one of the most reputable and influential IT companies in India. Their success story serves as an inspiration to aspiring entrepreneurs and technologists across the nation. Through their relentless pursuit of innovation, dedication to customer satisfaction, and commitment to social responsibility, The Space Element has not only transformed businesses but also contributed to the growth and progress of the IT industry in India.
          </p>
        </motion.div>
      </WhySuccessWrapperStyle>
      <ManageSecStyle>
        <h1>
          Managed <span>By</span>
        </h1>

        <ManageOneContainer>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.75, delay: 0.2 }}
            className="profile"
          >
            <img src={ManishImg} alt="profile" />
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.75, delay: 0.3 }}
            className="info"
          >
            <h2>Manish Sinha</h2>
            <p>
              Mr. Manish Sinha has over 5 years of experience in the software
              industry. As the M.D. of TSPACEE Design Studio, his primary focus
              is to ensure the company's continued growth and evolvement to meet
              the ever increasing demand for its business software systems and
              services in addition to the vital needs of its clients. Prior to
              starting Tspacee Design Studio, Mr. Sinha was affiliated with TEK
              (The Eat Kit) fruit Delivery Company. where he developed his
              Management career. Mr. Sinha earned a B.E from NIT.
            </p>
          </motion.div>
        </ManageOneContainer>

        <ManageOneContainer className="manage-two">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.75, delay: 0.2 }}
            className="info"
          >
            <h2>Swapnil Naik</h2>
            <p>
              The Co-Founder & CTO of TSPACEE Design Studio. I am a tech
              enthusiasts and a passionate programmer. Love to learn new
              technologies and love to teach technologies. I have an overall 7
              years of experience in programming. JavaScript is one of my
              favourite languages(Still a lot to learn).
            </p>
            <p>
              As a co-founder, I bring a unique blend of technical expertise, strategic thinking, and a deep understanding of market trends, allowing me to steer our company towards success in a rapidly evolving industry.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.75, delay: 0.3 }}
            className="profile"
          >
            <img src={SwapnilImg} alt="profile" />
          </motion.div>
        </ManageOneContainer>
      </ManageSecStyle>
      <OurVisionStyle
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.75, delay: 0.2 }}
      >
        <h1>
          Our <span>Mission</span>
        </h1>

        <p>
          Our Mission is to create a vibrant and inclusive community of technology enthusiasts who are passionate about the transformative power of technology. We believe that technology has the potential to reshape the world in unprecedented ways and improve the lives of people everywhere.
        </p>
        <p>
          In our Technology Enthusiast World, we strive to foster collaboration, innovation, and knowledge-sharing among like-minded individuals. We provide a platform for people to connect, exchange ideas, and stay updated on the latest advancements across various technological domains.
        </p>
        <p>
          Through engaging discussions, informative articles, interactive workshops, and exciting events, we aim to inspire and empower individuals to explore, embrace, and master emerging technologies. Whether it's artificial intelligence, blockchain, virtual reality, or any other cutting-edge field, we encourage our members to dive deep into their areas of interest and become active contributors to the technology landscape.
        </p>
        <p>
          We believe in the democratization of technology, making it accessible to all regardless of their background or expertise. By bridging the gap between technologists and non-technologists, we create an environment where everyone can learn, grow, and leverage technology to create a positive impact.
        </p>
        <p>
          In our Technology Enthusiast World, we also emphasize ethical considerations and responsible innovation. We encourage our community to think critically about the implications of technology on society, to ensure that we build a future that is inclusive, sustainable, and beneficial for all.
        </p>
        <p>
          Together, we can shape the future through our collective knowledge, passion, and enthusiasm for technology. Join us in our mission to create a world where technology thrives, ideas flourish, and possibilities abound.
        </p>
      </OurVisionStyle>
      <TestimonialsSecStyle>
        <div className="testimonials-header">
          <h1>Testimonials</h1>
        </div>

        <ReviewsContainerStyle>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              375: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              999: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              280: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
            }}
          >
            {testimonials.map((testimonial) => (
              <SwiperSlide key={testimonial._id}>
                <div className="review">
                  <div className="stars">
                    <span>⭐⭐⭐⭐⭐ 5.0</span>
                  </div>
                  <p>{testimonial?.reviews}</p>

                  <div className="divider"></div>
                  <div className="user">
                    <div className="user-img">
                      <img
                        src={`${IMG_URL}` + testimonial?.pro_pic}
                        alt={testimonial?.name}
                      />
                    </div>
                    <div className="user-info">
                      <h4>{testimonial?.name}</h4>
                      <small>{testimonial?.position}</small>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </ReviewsContainerStyle>
      </TestimonialsSecStyle>
    </main>
  );
};

export default AboutUs;
