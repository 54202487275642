import React, { useEffect, useState } from "react";
import { OurGallerySecStyle, GalleryContainerStyle } from "./ourgallery.style";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import axios from "axios";
import { APIS, IMG_URL } from "../../utils/Constants";

const OurGallery = () => {
  const [gallery, setGallery] = useState([]);
  const [isLightboxOpen, setIsLightBoxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const fetchGallery = async () => {
    try {
      const response = await axios.get(APIS.getURLS.getAllGallery);

      if (response.data.status === "success") {
        setGallery(response.data.data[0].TSEgallery);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchGallery();
  });

  let images = gallery.map((index) => IMG_URL + "/" + index);
  let thumbs = gallery.map((index) => IMG_URL + "/" + index);

  return (
    <main>
      <OurGallerySecStyle>
        <div className="gallery-header">
          <h1>Gallery</h1>
        </div>
      </OurGallerySecStyle>
      <GalleryContainerStyle>
        {gallery?.map((pic, index) => (
          <div className="card-img-container" key={index}>
            <img
              src={IMG_URL + "/" + pic}
              alt="pics"
              onClick={() => {
                setIsLightBoxOpen(true);
                setPhotoIndex(index);
              }}
            />
          </div>
        ))}
      </GalleryContainerStyle>
      {isLightboxOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          mainSrcThumbnail={thumbs[photoIndex]}
          nextSrcThumbnail={thumbs[(photoIndex + 1) % thumbs.length]}
          prevSrcThumbnail={
            thumbs[(photoIndex + thumbs.length - 1) % thumbs.length]
          }
          onCloseRequest={() => setIsLightBoxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          clickOutsideToClose={true}
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          imageCaption={photoIndex + 1 + "/" + images.length}
        />
      )}
    </main>
  );
};

export default OurGallery;
