import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ourWorkLottie from "../../assets/our-work-lottie.json";
import Lottie from "react-lottie";
import axios from "axios";
import { motion } from "framer-motion";
import {
  OurWorkIntroStyle,
  IntroLeftStyle,
  IntroRightStyle,
  RecentWrapperStyle,
  RecentOurWorkStyle,
  RecentListStyle,
} from "./ourwork.style";
import { ourWorkData, ourAppData } from "../../utils/OurWorkData";
import { APIS, IMG_URL } from "../../utils/Constants";

const ourWorkptions = {
  loop: true,
  autoplay: true,
  animationData: ourWorkLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const OurWork = () => {
  const [categoryText, setCatetgoryText] = useState("Web");
  const [webApps, setWebApps] = useState([]);
  const [mobApps, setMobApps] = useState([]);

  const fetchWebApps = async () => {
    try {
      const resposne = await axios.get(APIS.getURLS.getAllWebApps);
      setWebApps(resposne.data.data);
      console.log("Web", resposne);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMobApps = async () => {
    try {
      const response = await axios.get(APIS.getURLS.getAllMobApps);
      setMobApps(response.data.data);
      console.log("Apps", response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchWebApps();
    fetchMobApps();
    window.scrollTo(0, 0);
  }, []);

  return (
    <main
      style={{
        maxWidth: "1500px",
        paddingRight: "calc(var(--bs-gutter-x) * 0.5)",
        paddingLeft: "calc(var(--bs-gutter-x) * 0.5)",
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      <OurWorkIntroStyle>
        <IntroLeftStyle
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
        >
          <h2>
            What <span> we’ve </span>been working on
          </h2>
          <p>
            Our expertise, experience & curiosity help us research & adopt
            innovations and technologies, to craft a better solution to your
            unique & challenging needs.
          </p>
        </IntroLeftStyle>
        <IntroRightStyle
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.2 }}
        >
          <Lottie options={ourWorkptions} width="80%" height="80%" />
        </IntroRightStyle>
      </OurWorkIntroStyle>

      <RecentWrapperStyle>
        <RecentOurWorkStyle checkCategory={categoryText}>
          <h2>Our recent work</h2>
          <p>Check out our recent work</p>
          <div className="recent-btns">
            <button onClick={() => setCatetgoryText("Web")}>Web</button>
            <button onClick={() => setCatetgoryText("App")}>App</button>
          </div>
        </RecentOurWorkStyle>

        <RecentListStyle>
          {categoryText === "Web" ? (
            <>
              {webApps.map((web, index) => (
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.75, delay: 0.2 }}
                  className="work-card"
                  key={index}
                >
                  <Link to={`/detail/${web._id}`}>
                    <img
                      src={IMG_URL + "/" + web.thumbnailImg[0]}
                      alt={web.project_title}
                    />

                    <div className="info">
                      <h4>{web.project_title}</h4>
                      {/* <a href="https://www.google.com/">Visit Site</a> */}
                    </div>
                  </Link>
                </motion.div>
              ))}
            </>
          ) : (
            <>
              {mobApps.map((app, index) => (
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.75, delay: 0.2 }}
                  className="work-card"
                  key={index}
                >
                  <Link to={`/app/detail/${app?._id}`}>
                    <img
                      src={IMG_URL + "/" + app?.thumbnailImg}
                      alt={app?.project_title}
                    />

                    <div className="info">
                      <h4>{app?.project_title}</h4>
                    </div>
                  </Link>
                </motion.div>
              ))}
            </>
          )}
        </RecentListStyle>
      </RecentWrapperStyle>
    </main>
  );
};

export default OurWork;
