import styled from "styled-components";

export const CardContainerStyle = styled.div`
  padding: 1rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.39) 0%,
    rgba(255, 255, 255, 0.09) 0.01%,
    rgba(255, 255, 255, 0.12) 100%
  );
  border: 0.1rem solid rgba(255, 255, 255, 0.39);
  border-radius: 1rem;

  .vacancy-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      color: white;
      text-transform: capitalize;

      @media screen and (max-width: 768px) {
        font-size: 0.7rem;
      }
    }

    a {
      text-decoration: none;
      background-color: #ec2024;
      font-family: inherit;
      font-weight: bold;
      padding: 0.8rem 1.5rem;
      color: white;
      border-radius: 0.5rem;

      @media screen and (max-width: 768px) {
        padding: 0.5rem 1rem;
        text-align: center;
        font-size: 0.8rem;
      }
    }
  }

  .divider-line {
    width: 100%;
    height: 0.1rem;
    background-color: #6d6d6d;
    margin: 2rem 0rem;

    @media screen and (max-width: 768px) {
      margin: 1rem 0rem;
    }
  }

  .vacancy-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .vacant-info-left {
      display: flex;
      gap: 3rem;
      flex: 1;

      @media screen and (max-width: 768px) {
        gap: 1rem;
      }

      @media screen and (max-width: 768px) {
        flex: auto;
      }

      .vacant {
        display: flex;
        align-items: center;
        gap: 1rem;

        @media screen and (max-width: 768px) {
          img {
            width: 1rem;
            height: 1rem;
            object-fit: cover;
          }

          gap: 0.5rem;
        }

        span {
          display: inline-block;
          color: white;
          text-transform: capitalize;

          @media screen and (max-width: 768px) {
            font-size: 0.6rem;
          }
        }
      }
    }

    .vacant-info-right {
      flex: 1;

      @media screen and (max-width: 768px) {
        flex: auto;
      }

      a {
        float: right;
        margin-right: 1.2rem;
        color: #2196f3;

        @media screen and (max-width: 768px) {
          margin-right: 0;
          font-size: 0.5rem;
        }
      }
    }
  }
`;

export const ViewDetailsStyle = styled.div`
  .job-description {
    h4 {
      padding: 1rem 0rem;
      color: #f44336;

      @media screen and (max-width: 768px) {
        font-size: 0.8rem;
        padding: 0.5rem 0rem;
      }
    }

    ul {
      li {
        margin: 0.5rem 0rem 0rem 1rem;
        color: white;
        list-style: disc;
        @media screen and (max-width: 768px) {
          font-size: 0.7rem;
        }
      }
    }
  }

  .skills {
    h4 {
      padding: 1rem 0rem;
      color: #f44336;

      @media screen and (max-width: 768px) {
        font-size: 0.8rem;
        padding: 0.5rem 0rem;
      }
    }
    span {
      display: inline-block;
      padding: 0.5rem 1rem;
      border: 0.1rem solid white;
      margin: 0rem 2rem 0rem 0rem;
      border-radius: 2rem;
      color: white;

      @media screen and (max-width: 768px) {
        font-size: 0.8rem;
        margin: 1rem 1rem 0rem 0rem;
      }
    }
  }

  .profile-description {
    h4 {
      padding: 1rem 0rem;
      color: #f44336;

      @media screen and (max-width: 768px) {
        font-size: 0.8rem;
        padding: 0.5rem 0rem;
      }
    }

    .profile-type {
      display: flex;

      .type {
        width: 13rem;

        h5 {
          color: white;
          font-weight: lighter;
          font-size: 1rem;
          margin: 0.5rem 0rem 0rem 0rem;

          @media screen and (max-width: 768px) {
            font-size: 0.8rem;
          }
        }
      }

      .type-match {
        width: 14rem;

        h5 {
          color: white;
          font-weight: lighter;
          font-size: 1rem;
          margin: 0.5rem 0rem 0rem 0rem;

          @media screen and (max-width: 768px) {
            font-size: 0.8rem;
            margin: 0rem 0rem 0rem 0rem;
          }
        }
      }
    }
  }
`;
