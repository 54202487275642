import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import {
  Home,
  OurWork,
  ProjectDetail,
  ContactUs,
  AboutUs,
  OurGallery,
  Career,
  ProjectDetailApp,
} from "./pages";
import { Navbar, Footer } from "./components";
import Careernew1 from "./pages/Training_with_tse/training_tse";

const App = () => {
  return (
    <Fragment>
      <Navbar />
      <Toaster position="top-center" />
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/ourwork" element={<OurWork />} />
        <Route path="/detail/:id" element={<ProjectDetail />} />
        <Route path="/app/detail/:id" element={<ProjectDetailApp />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/ourgallery" element={<OurGallery />} />
        <Route path="/career" element={<Career />} />
        <Route path="/training" element={<Careernew1 />} />
      </Routes>
      <Footer />
    </Fragment>
  );
};

export default App;
